import { processQuery, get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

import { getSiteInfo } from './siteinfo'
import { getLot as refreshLot } from './lot'

const base = 'owa/auction/'

const DATA_BIDS = defineAction(base, 'DATA_BIDS', 'async')
const DATA_WATCHLIST = defineAction(base, 'DATA_WATCHLIST', 'async')
const BID = defineAction(base, 'BID', 'async', { customActions: ['register', 'needs_review'] })
const WATCH = defineAction(base, 'WATCH', 'async')
const UNWATCH = defineAction(base, 'UNWATCH', 'async')

const CLEAR_DATA = base.concat('CLEAR_DATA')
const INPUT_CHANGE = base.concat('INPUT_CHANGE')

const potentialBid_defaults = {
  bid_source: 0,
  bid_type: 0,
  original_bid_type: -1,
  lot_id: null,
  resolved_bid: 0,
  lot_number: 0,
  lot_title: null,
  bid_user_id: null,
  visible_high_bid: null,
  original_max_bid: null,
  sanitized_max_bid: null,
  final_max_bid: null,
  new_high_bid: null,
  original_high_bid: null,
  is_reserve_met: false,
  is_high_bidder: false,
  bidresult: 0,
  proper: false,
  valid: false,
  message_info: [],
  meta: status_defaults,
}

const initialState = {
  potentialBid: potentialBid_defaults,
  bidProcessing: false,
  buyProcessing: false,
  watchProcessing: false,
  bids: {
    data: {},
    meta: status_defaults,
  },
  bidorder: [],
  watchlist: {
    data: {},
    meta: status_defaults,
  },
  watchlistorder: [],
}

function updateBids(state, action) {
  let _bids_order = []

  const _bids = action.payload.data.reduce((o, bid, i) => {
    if (typeof state.bids.data[bid.lot_number] !== "undefined") {
      o[bid.lot_number] = {
        ...state.bids.data[bid.lot_number],
        price_minimumbid: bid.price_minimumbid,
        price_highbid: bid.price_highbid,
        current_max_bid: bid.current_max_bid,
        is_high_bidder: bid.is_high_bidder,
        reserve_none: bid.reserve_none,
        reserve_met: bid.reserve_met,
        status_closed: bid.status_closed,
        date_lastbid: bid.date_lastbid,
        time_since_lastbid: bid.time_since_lastbid,
        time_remaining: bid.time_remaining,
        bid_info_stale: false,
      }
    } else {
      //add new item
      o[bid.lot_number] = {
        ...bid,
        max_bid: '',
        bid_info_stale: false,
      }
    }
    _bids_order[i] = bid.lot_number
    return o
  }, {})
  return {
    ...state,
    bids: {
      ...state.bids,
      data: _bids,
      meta: {
        ...status_defaults,
        ...action.meta,
      }
    },
    bidorder: _bids_order,
  }
}

function updateWatchlist(state, action) {
  let _watchlist_order = []

  const _watchlist = action.payload.data.reduce((o, item, i) => {
    if (typeof state.watchlist.data[item.lot_number] !== "undefined" && state.watchlist.data[item.lot_number].skeleton === false) {
      o[item.lot_number] = {
        ...state.watchlist.data[item.lot_number],
        price_minimumbid: item.price_minimumbid,
        price_highbid: item.price_highbid,
        reserve_none: item.reserve_none,
        reserve_met: item.reserve_met,
        status_closed: item.status_closed,
        date_lastbid: item.date_lastbid,
        time_since_lastbid: item.time_since_lastbid,
        time_remaining: item.time_remaining,
        skeleton: false,
      }
    } else {
      //add new item (or populate full data for lots added to watchlist since last full page reload)
      o[item.lot_number] = {
        ...item,
        max_bid: '',
        visible: true,
        skeleton: false,
      }
    }
    _watchlist_order[i] = item.lot_number
    return o
  }, {})
  return {
    ...state,
    watchlist: {
      ...state.watchlist,
      data: _watchlist,
      meta: {
        ...status_defaults,
        ...action.meta,
      }
    },
    watchlistorder: _watchlist_order,
    watchlist_promoting: false,
  }
}

function updatePotentialBid(previous, action) {
  return {
    ...previous,
    ...action.payload.ebid.values,
    message_info: action.payload.ebid.message_info,
    bidresult: action.payload.bidresult,
    proper: action.payload.ebid.proper,
    valid: action.payload.ebid.valid,
    // unconstrained: action.payload.ebid.unconstrained,
    // ignore_reserve: action.payload.ebid.ignore_reserve,
    meta: {
      ...status_defaults,
      ...action.meta,
    }
  }
}

function updateIndividualBidData(state, action) {
  const pb = updatePotentialBid(state.potentialBid, action)
  const source = pb.bid_source === 1 ? 'bids' : (pb.bid_source === 2 ? 'watchlist' : null)
  if (source) {
    return {
      potentialBid: pb,
      [source]: {
        ...state[source],
        data: {
          ...state[source].data,
          [pb.lot_number]: {
            ...state[source].data[pb.lot_number],
            max_bid: '',
            price_highbid: pb.new_high_bid,
            current_max_bid: pb.final_max_bid,
            is_high_bidder: pb.is_high_bidder,
            reserve_met: pb.is_reserve_met,
            bid_info_stale: state[source].data[pb.lot_number].price_highbid !== pb.new_high_bid,
            ...(source === 'watchlist' && { promoting: true }),
          }
        }
      },
      ...(source === 'watchlist' && { watchlist_promoting: true })
    }
  } else {
    return {
      potentialBid: pb,
    }
  }
}

  // bid_source: 0 = lot detail page
  // bid_source: 1 = bid status
  // bid_source: 2 = watchlist
  // bid_type: 0 = normal bid
  // bid_type: 1 = quik bid
  // bid_type: 2 = buy it now

export default function reducer(state = initialState, action = {}) {
  //deepFreeze(state)
  switch (action.type) {
    case CLEAR_DATA:
      return initialState

    case DATA_BIDS.request:
      return {
        ...state,
        bids: {
          ...state.bids,
          meta: {
            ...status_defaults,
            processing: true,
            timestamp: state.bids.meta.timestamp,
          }
        }
      }
    case DATA_BIDS.success:
      return {
        ...state,
        ...updateBids(state, action),
      }
    case DATA_BIDS.fail:
      return {
        ...state,
        bids: {
          ...state.bids,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_WATCHLIST.request:
      return {
        ...state,
        watchlist: {
          ...state.watchlist,
          meta: {
            ...status_defaults,
            processing: true,
            timestamp: state.watchlist.meta.timestamp,
          }
        }
      }
    case DATA_WATCHLIST.success:
      return {
        ...state,
        ...updateWatchlist(state, action),
      }
    case DATA_WATCHLIST.fail:
      return {
        ...state,
        watchlist: {
          ...state.watchlist,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }

    // updated below
    case BID.register:
      return {
        ...state,
        potentialBid: {
          ...potentialBid_defaults,
          ...action.payload,
        },
      }
    case BID.request:
      return {
        ...state,
        potentialBid: {
          ...state.potentialBid,
          meta: {
            ...status_defaults,
            processing: true,
          }
        },
      }
    case BID.needs_review:
      return {
        ...state,
        potentialBid: updatePotentialBid(state.potentialBid, action),
      }
    case BID.success:
      return {
        ...state,
        ...updateIndividualBidData(state, action),
      }
    case BID.fail:
      return {
        ...state,
        potentialBid: {
          ...state.potentialBid,
          meta: {
            processing: false,
            ...action.meta,
          }
        }
      }
    case WATCH.request:
      return {
        ...state,
        watchlist: {
          ...state.watchlist,
          data: {
            ...state.watchlist.data,
            [action.foundation.lot_number]: {
              processing: true,
            }
          }
        }
      }
    case WATCH.fail:
      return {
        ...state,
        watchlist: {
          ...state.watchlist,
          data: {
            ...state.watchlist.data,
            [action.foundation.lot_number]: null,
          }
        }
      }
    case WATCH.success:
      return {
        ...state,
        watchlist: {
          ...state.watchlist,
          data: {
            ...state.watchlist.data,
            [action.foundation.lot_number]: {
              lot_id: action.foundation.lot_id,
              skeleton: true,
            }
          }
        }
      }
    case UNWATCH.request:
      return {
        ...state,
        watchlist: {
          ...state.watchlist,
          data: {
            ...state.watchlist.data,
            [action.foundation.lot_number]: {
              ...state.watchlist.data[action.foundation.lot_number],
              processing: true,
            }
          }
        }
      }
    case UNWATCH.fail:
      return {
        ...state,
        watchlist: {
          ...state.watchlist,
          data: {
            ...state.watchlist.data,
            [action.foundation.lot_number]: {
              ...state.watchlist.data[action.foundation.lot_number],
              processing: false,
            }
          }
        }
      }
    case UNWATCH.success:
    {
      return {
        ...state,
        watchlist: {
          ...state.watchlist,
          data: {
            ...state.watchlist.data,
            [action.foundation.lot_number]: null,
          }
        }
      }
    }
    case INPUT_CHANGE:
      return {
        ...state,
        [action.source]: {
          ...state[action.source],
          data: {
            ...state[action.source].data,
            [action.selected_lot_number]: {
              ...state[action.source].data[action.selected_lot_number],
              max_bid: action.input,
            }
          }
        }
      }
    default:
      return state
  }
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  }
}

export function placeBid(sourceData, query) {
  return (dispatch) => {
    // bid_type: 0 = normal bid
    // bid_type: 1 = quik bid
    // bid_type: 2 = buy it now
    if (sourceData.bid_type === 1) {
      dispatch(bidRegister(sourceData))
      return dispatch(bidConfirm(query))
    } else {
      return dispatch(bidRegister(sourceData))
    }
  }
}

export function bidRegister(sourceData) {
  console.log('**bidRegister**')
  return {
    type: BID.register,
    payload: { ...sourceData },
  }
}

// ZZZ - test out all types of bids (detail page, bid status, watchlist)
export function bidConfirm(query) {
  console.log('**bidConfirm**')
  return (dispatch, getState) => {
    const state = getState()
    const pb = state.auction.potentialBid

    let endpoint
    let postdata
    if (pb.bid_type === 2) {
      endpoint = '/api/user/auction/buy/' + pb.lot_id
      postdata = { bid_user_id: pb.bid_user_id }
    } else {
      endpoint = '/api/user/auction/bid/' + pb.lot_id
      postdata = { bid_type: pb.bid_type, bid_value: pb.resolved_bid, bid_user_id: pb.bid_user_id, visible_high_bid: pb.visible_high_bid }
    }

    return dispatch(
      get({
        types: BID,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: postdata,
        },
        onSuccess: (response) => {
          if (response.payload.bidresult === 1) {
            // success
            return dispatch({ type: BID.success, payload: response.payload })
          } else {
            // needs review
            return dispatch({ type: BID.needs_review, payload: response.payload })
          }
        },
        postSuccess: () => {
          if (pb.bid_source === 0) {
            // refresh lot detail page
            dispatch(refreshLot(state.lot.content.data.auction_id, state.lot.content.data.lot_number))
            dispatch(getBids(query))
          }
        }
      })
    )
  }
}

export function inputChange(name, value, options={}) {
  return {
    type: INPUT_CHANGE,
    source: options.bid_source === 1 ? 'bids' : 'watchlist',
    input: value,
    selected_lot_number: options.lot_number,
  }
}

export function toggleWatch(data) {
  return (dispatch) => {
    const { watch, source, query, ...info } = data
    const endpoint = '/api/user/auction/' + (watch === 1 ? 'watch' : 'unwatch')
    return dispatch(
      get({
        types: watch === 1 ? WATCH : UNWATCH,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: info,
        },
        foundation: info,
        postSuccess: () => {
          dispatch(getSiteInfo())
          if (watch === 0 && source === 0) {
            dispatch(getWatchlist(query))
          }
        }
      })
    )
  }
}

function getBids(query) {
  console.log('**getBids')
  return (dispatch, getState) => {
    const querydata = processQuery(query, { pageSize: 0, sortKey: 'bids' })
    const endpoint = '/api/user/auction/getbids' + querydata
    return dispatch(
      get({
        types: DATA_BIDS,
        endpoint: endpoint,
        postSuccess: (result) => {
          if (getState().siteinfo.data.cachetime !== result.payload.siteinfo_cachetime) {
            console.log('*SITEINFO NEEDS REFRESH!*')
            dispatch(getSiteInfo())
          }
        }
      })
    )
  }
}

function getWatchlist(query) {
  console.log('**getWatchlist')
  return (dispatch) => {
    const querydata = processQuery(query, { pageSize: 0, sortKey: 'watchlist' })
    const endpoint = '/api/user/auction/getwatchlist' + querydata
    return dispatch(
      get({
        types: DATA_WATCHLIST,
        endpoint: endpoint,
      })
    )
  }
}

export function getUserData(query={}) {
  return (dispatch, getState) => {
    if (!getState().auth.login.data.isAuthenticated) {
      return null
    }
    return Promise.all([
      dispatch(getBids(query)),
      dispatch(getWatchlist(query))
    ])
  }
}
