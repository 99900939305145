import React from 'react'
import { browserHistory } from 'react-router'
import { diff } from 'deep-diff'
import qs from 'qs'
import { format as dateFNSformat, parseISO, toDate } from 'date-fns'

// Utility functions used in admin components

export function formatMoney(n, { minimumFractionDigits=null, maximumFractionDigits=null }={}) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // currencySign: 'accounting', // can't use this for now because specific versions of Safari 14 break (workaround to add parenthesis around negative numbers below)
    ...(minimumFractionDigits !== null && { minimumFractionDigits }),
    ...(maximumFractionDigits !== null && { maximumFractionDigits }),
  })

  if (n == 0) {
    return formatter.format(0)
  } else if ( n < 0) {
    return '(' + formatter.format(Math.abs(n)) + ')'
  } else {
    return formatter.format(n)
  }
}

export function formatMoneyInteger(n) {
  if (!Number.isInteger(Number(n))) {
    return '$0'
  } else {
    return formatMoney(n, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }
}

export function round(value, decimals) {
  return +(Math.round(value + "e+" + decimals)  + "e-" + decimals)
}

export function numOrZero(x) {
  if (Number(x)) {
    return Number(x)
  } else {
    return 0
  }
}

export function integerOrZero(v) {
  if (typeof v === 'undefined') { return 0 }
  const num = +v
  if (Number.isInteger(num)) {
    return num
  } else {
    return 0
  }
}

export function forceInteger(v) {
  if (typeof v === 'undefined') { return null }
  const num = +v
  if (Number.isInteger(num)) {
    return num
  } else {
    return null
  }
}

export function formatValue(value, format="string") {
  if (typeof value === 'undefined' || value === null) {
    return ''
  }

  switch (format) {
    case 'currency-integer':
      return formatMoneyInteger(value)
    case 'currency-decimal':
      return formatMoney(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    case 'percent':
      if (value !== null) {
        return value + '%'
      } else {
        return '-'
      }
    default:
      return value
  }
}

export function nullToEmptyString(v) {
  return v === null ? '' : v
}

export function sanitizeString(s) {
  return s.trim().replace(/\s+/g, ' ')
}

// return sanitized string (if source is string), otherwise return null
// empty strings are also converted to null
export function sanitizedStringOrNull(s) {
  const ss = typeof s === 'string' ? sanitizeString(s) : null
  return ss === '' ? null : ss
}

export function nullOrEmptySanitizedString(s) {
  return sanitizedStringOrNull(s) === null
}

export function allNullOrEmptySanitizedStrings(values) {
  return values.every(s => sanitizedStringOrNull(s) === null)
}

export function containsOnlyNulls(values) {
  return values.every(v => v === null)
}

export function containsOnlyNonEmptyStringData(values) {
  return values.every(v => typeof v === 'string' && v !== '')
}

export function resolveImplicitInputOptions(target) {
  const id = typeof target.getAttribute === 'function' && target.getAttribute('data-id')
  return {
    ...(id && { id: id }),
    valid: target?.validity?.valid,
  }
}

// ZZZ - still needs a lot of work to handle other types
// also need to handle leaving param for types other than float
export function sanitizeInput(target, { leaving=false }={}) {
  const dataType = typeof target.getAttribute === 'function' && target.getAttribute('data-type') ? target.getAttribute('data-type') : 'string'
  if (target.type === 'checkbox') {
    // default to integer type unless boolean specified for checkboxes
    return dataType === 'boolean' ? (target.checked ? true : false) : (target.checked ? 1 : 0)
  } else {
    // all other input types should be processed here
    const value = target.value
    // allow empty strings when typing in inputs
    if (value === '') { return '' }

    switch (dataType) {
      case 'unsigned-int':
      {
        const i = Number(value)
        if (i < 0) {
          return null
        } else if (!Number.isInteger(i)) {
          return null
        } else {
          return i
        }
      }
      case 'int':
      {
        const i = Number(value)
        if (!Number.isInteger(i)) {
          return null
        } else {
          return i
        }
      }
      case 'float':
        if (value === '-' && !leaving) {
          return '-'
        } else if (value === '.' && !leaving) {
          return '0.'
        } else {
          const sanitized_val = value.match(/-{0,1}[\d]+\.{0,1}[\d]{0,2}/, "")
          return sanitized_val === null || isNaN(+sanitized_val[0]) ? null : (leaving ? +sanitized_val[0] : sanitized_val[0])
        }
      case 'credit-card-number':
        return value.replace(/\D/g,'')
      case 'credit-card-expiration':
        // look into library like cleave.js for better validation of special input types
        return value.replace(/[^0-9/]/g,'')
      // don't need this because we sanitize all strings in the same way, leaving just in case there ends up being an edge case
      // case 'space-regulated-string':
      //   return leaving ? sanitizeString(value) : value
      default:
        //string
        // if leaving field, sanitize the string value by trimming, converting other whitespace characters to spaces, and reducing multiple spaces to a single space
        return leaving ? sanitizeString(value) : value
    }
  }
}

// generate select options from passed in array
export function makeOptions(opts, { showVal=false, emptyOption=false, customOption=null, filterHidden=false }={}) {
  const resolvedOpts = filterHidden ? opts.filter((opt) => !opt.hidden) : opts
  return [
    ...(emptyOption ? [<option value="" key="empty" />] : []),
    ...(customOption ? [<option value={customOption.value} key={"custom-" + customOption.value} >{customOption.text}</option>] : []),
    ...resolvedOpts.map(function (opt, i) {
      let displayval = '';
      if (showVal) {
        displayval = (opt.value != null && opt.value != opt.text ? opt.value + ' - ' + opt.text : opt.text);
      } else {
        displayval = opt.text;
      }
      return <option value={opt.value} key={i + '-' + opt.value}>{displayval}</option>
    })
  ]
}

// generate select options for either yesno or truefalse fields
export function makeOptionsBoolean({ type = 'yesno', emptyOption = false, customOption = null } = {}) {
  // type is either 'yesno' or 'truefalse'
  const opts = [
    ...(emptyOption ? [{ value: '', text: '', key: 'empty' }] : []),
    { value: 0, text: type === 'yesno' ? 'No' : 'False' },
    { value: 1, text: type === 'yesno' ? 'Yes' : 'True' },
    ...(customOption ? [{ value: customOption.value, text: customOption.text, key: 'custom-' + customOption.value }] : []),
  ]
  return opts.map(function(opt, i) {
    return <option value={opt.value} key={i}>{opt.text}</option>
  })
}

// convert seconds into HH:MM:SS
export function toHHMMSS(rawseconds) {
    var sec_num = parseInt(rawseconds, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

// convert seconds into MM:SS
export function toMMSS(rawseconds) {
    var sec_num = parseInt(rawseconds, 10);
    var minutes = Math.floor(sec_num / 60);
    var seconds = sec_num - (minutes * 60);

    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return minutes+':'+seconds;
}

export function formatServerValue(branch, { format='string', showPercentage=false, totalInfo=null }={}) {
  if (typeof branch === 'undefined' || branch === null) {
    return '<Undefined>'
  } else {
    if (branch.meta.status === 1) {
      let result = formatValue(branch.payload.value, format)
      if (showPercentage && typeof totalInfo !== 'undefined') {
        const val = numOrZero(branch.payload.value)
        const total = numOrZero(totalInfo.value)
        if (total > 0) {
          const percent = round(val / total * 100, 2)
          result += ' (' + percent + '%)'
        }
      }
      return result
    } else {
      return branch.meta.statusText || '<No Value>'
    }
  }
}

/**
 * hydrateQueryFields and clearQueryFields used by redux modules to connect and clear URL query parameters to the store
 */

export function hydrateQueryFields(q, state_fields) {
  const f = {}
  Object.keys(state_fields).map((key) => {
    if (typeof q[key] !== 'undefined') {
      f[key] = q[key]
    }
  })
  return f
}

export function clearQueryFields(state_fields) {
  const f = {}
  Object.keys(state_fields).map((key) => {
    f[key] = ''
  })
  return f
}

// create formatted string of query parameters with hidden items removed (returns null if no query parameters)
export function formatQueryParams(query, { hidden_items=[], hide_sort_and_paging=true }={}) {
  // let q = Object.assign({}, query)
  // if (hide_sort_and_paging) { removeSortPagingFromQuery(q) }
  const q = hide_sort_and_paging ? filterObjectKeysStartingWith(query, ['page', 'sort_field', 'sort_table', 'sort_direction']) : query
  if (hidden_items && hidden_items.length > 0) {hidden_items.map((item) => delete q[item])}
  if (Object.getOwnPropertyNames(q).length === 0) {
    return null
  } else {
    const formatted = Object.keys(q).map((key, index) => { return key + ':' + q[key] }).join(', ')
    return formatted
    //return JSON.stringify(q).replace(/\"|{|}/g, "")
  }
}

export function objectHasOneOfKeys(obj, keyList=[]) {
  return keyList.some(key => {
    return typeof obj[key] !== 'undefined' && obj[key] !== undefined && obj[key] !== null && obj[key] !== ''
  })
}

function filterObjectKeysStartingWith(obj, keysToRemove=[]) {
  let actual_keys = []
  for (var k in obj){
    keysToRemove.map(key => {
      if (k.indexOf(key) === 0) {
        actual_keys.push(k)
      }
    })
  }
  return actual_keys.length > 0 ? filterObjectKeys(obj, actual_keys) : obj
}

export function filterObjectKeys(obj, keysToRemove=[]) {
  return Object.keys(obj)
  .filter(key => !keysToRemove.includes(key))
  .reduce((accumulator, key) => {
    accumulator[key] = obj[key]
    return accumulator
  }, {})
}

export function filterObjectKeysExcept(obj, keysToKeep=[]) {
  return Object.keys(obj)
  .filter(key => keysToKeep.includes(key))
  .reduce((accumulator, key) => {
    accumulator[key] = obj[key]
    return accumulator
  }, {})
}

export function buildPath(basePath, target=null) {
  return target ? basePath + '/' + target : basePath
}

export function getLinkCanonical(location, hideParams=[]) {
  const cleansed_query = filterObjectKeys(location.query, hideParams)
  return location.pathname + qs.stringify(cleansed_query, { addQueryPrefix: true })
}

export function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function getKeyBySubValue(object, value) {
  return Object.keys(object).find(key => object[key].value === value);
}

export function generateUUID () { // Public Domain/MIT
    var d = Date.now();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

// convert camelCase string to hyphenated version
export function camelCaseToDash (str) {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase()
}

// only used for dev mode testing to generate delays in actions
export function sleep(ms) {
  return new Promise( resolve => {
    setTimeout(resolve, ms)
  })
}

export function sanitizedDiff(o, n, itemsToObscure=[]) {
  const changes = diff(o, n)
  if (changes) {
    const sanitized_changes = changes.reduce((accum, item) => {
      const key = item.path[0]
      if (itemsToObscure.includes(key)) {
        accum[item.path[0]] = '<updated value, hidden for security>'
      } else {
        accum[item.path[0]] = item.lhs + ' -> ' + item.rhs
      }
      return accum
    }, {})
    return sanitized_changes
  } else {
    return null
  }
}

export const jsonFormatter = {
  replacer: function(match, pIndent, pKey, pVal, pEnd) {
    var key = '<span class=json-key>';
    var val = '<span class=json-value>';
    var str = '<span class=json-string>';
    var r = pIndent || '';
    if (pKey)
      r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
    if (pVal)
      r = r + (pVal[0] == '"' ? str : val) + pVal + '</span>';
    return r + (pEnd || '');
  },
  toHtml: function(obj) {
    var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
    return JSON.stringify(obj, null, 3)
      // below replace hides any line containing <#hidden#>
      .replace(/^.*<#hidden#>.*$/mg, "")
      .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
      .replace(/</g, '&lt;').replace(/>/g, '&gt;')
      .replace(jsonLine, jsonFormatter.replacer);
  }
}

export function isObject(value) {
  // very basic test for plain objects -- assumes no modification to prototype or constructor
  return value && typeof value === 'object' && value.constructor === Object
}

export function isNonEmptyObject(value) {
  return value && Object.entries(value).length !== 0 && value.constructor === Object
}

// More robust numeric checking - not currently used but may be at some point
export function isNumeric(obj) {
    var realStringObj = obj && obj.toString()
    return !Array.isArray(obj) && (realStringObj - parseFloat(realStringObj) + 1) >= 0
}

export function isError(e){
  return e && e.stack && e.message
}

export function resolveDate(i, { format='autodetect' }={}) {
  try {
    let resolvedDate
    if (typeof i === 'string') {
      let resolvedString = i
      const resolvedFormat = format === 'autodetect' ? (i.includes('/') ? 'us-slash' : (i.includes(':') ? 'iso-datetime' : 'iso-date')) : format
      switch (resolvedFormat) {
        case 'us-slash':
        {
          // convert to iso format so we can then check if date is valid
          // mm/dd/yyyy becomes yyyy-mm-dd
          // m/d/yyyy becomes yyyy-mm-dd
          if (i.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
            // passes us-slash format, now we convert it to iso format
            const [month, day, year] = i.split('/')
            resolvedString = year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0')
          } else {
            throw Error('date not in mm/dd/yyyy format')
          }
          break
        }
        case 'iso-date':
          if (i.match(/^\d{4}-\d{2}-\d{2}$/)) {
            // passes iso-date format yyyy-mm-dd
          } else {
            throw Error('date not in yyyy-mm-dd format')
          }
          break
        case 'iso-datetime':
          if (i.match(/^\d{4}-\d{2}-\d{2}( |T)\d{2}:\d{2}:\d{2}$/) || i.match(/^\d{4}-\d{2}-\d{2}( |T)\d{2}:\d{2}$/)) {
            // passes iso w/time format yyyy-mm-dd hh:mm:ss OR yyy-mm-dd hh:mm
          } else {
            throw Error('date not in yyyy-mm-dd(T)hh:mm(:ss) format')
          }
          break
        default: // raw (raw string)
          // no strict check, just allow raw string to be parsed
          // this would allow for strings in the yyyy-mm-ddThh:mm:ss.fffZ or yyyy-mm-ddThh:mm:ss+00:00 formats
          break
      }
      resolvedDate = parseISO(resolvedString)
    } else {
      // non-string input, will clone date object or resolve number to timestamp
      resolvedDate = toDate(i)
    }
    return resolvedDate
  } catch (e) {
    console.log('resolveDate-Error:', e.message)
    return null
  }
}

export function formatDate(i, { format='M/d/yyyy', errorOnInvalid=false }={}) {
  try {
    return dateFNSformat(parseDate(i), format)
  } catch(e) {
    console.log('formatDate-Error:', e.message)
    if (errorOnInvalid) {
      throw e
    } else {
      return null
    }
  }
}

// string type assumes ISO format
// if we want to create a date from a non-ISO string it needs to be converted before being passed to this function
export function parseDate(i) {
  if (typeof i === 'undefined') {
    return Date.now()
  } else {
    return typeof i === 'string' ? parseISO(i) : toDate(i)
  }
}

export function formatDatePadded(i, { errorOnInvalid=false }={}) {
  return formatDate(i, { format: 'MM/dd/yyyy', errorOnInvalid })
}

export function formatTimestamp(i, { errorOnInvalid=false }={}) {
  return formatDate(i, { format: 'yyyy-MM-dd HH:mm:ss', errorOnInvalid })
}

export function formattedCurrentDate({ format='M/d/yyyy', errorOnInvalid=false }={}) {
  return formatDate(Date.now(), { format, errorOnInvalid })
}

export function formatJSON(j) {
  return <pre>{JSON.stringify(j, undefined, 2)}</pre>
}

export function loginWithMemory(pathname) {
  browserHistory.push('/login?redirect=' + encodeURIComponent(pathname))
}

// ** Below Functions are potentially unused and may be removed **

function arrEqual(a, b) {
  return a.length === b.length && a.every((value, index) => value === b[index])
}

export function isInt(value) {
  return !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10));
}

// Generate globally-unique identifier -- old code
// export function GUID() {
//   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//     var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
//     return v.toString(16);
//   });
// }

// checks if passed in value is a valid number
export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function numberWithCommas(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}

// Unused - keeping for future reference
// function mysql_real_escape_string(str) {
//   return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
//     switch (char) {
//       case "\0":
//         return "\\0";
//       case "\x08":
//         return "\\b";
//       case "\x09":
//         return "\\t";
//       case "\x1a":
//         return "\\z";
//       case "\n":
//         return "\\n";
//       case "\r":
//         return "\\r";
//       case "\"":
//       case "'":
//       case "\\":
//       case "%":
//         return "\\"+char; // prepends a backslash to backslash, percent,
//                           // and double/single quotes
//       }
//   });
// }
