import React from 'react'
import PropTypes from 'prop-types'

import { bid_extension_time } from 'lib/constants'
import { toMMSS } from 'lib/utility'

LotTimeRemaining.propTypes = {
  auction_mode: PropTypes.number.isRequired,
  display_mode: PropTypes.number.isRequired,
  elapsedSeconds: PropTypes.number.isRequired,
  time_since_lastbid: PropTypes.number,
}

function LotTimeRemaining({ auction_mode, display_mode, elapsedSeconds, time_since_lastbid=null }) {
  if (auction_mode === 2 && display_mode === 1 && time_since_lastbid !== null) {
    const time_remaining = Math.max(0, bid_extension_time - (time_since_lastbid + elapsedSeconds)) || 0
    return <h5><span style={{fontWeight: 'bold', color: time_remaining <= 60 ? 'red' : 'orange' }}>{time_remaining > 0 ? ('Closing in: ' + toMMSS(time_remaining)) : 'Pending Close...'}</span></h5>
  } else {
    return null
  }
}

export default LotTimeRemaining
