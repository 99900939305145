import { useState, useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getLot } from 'rdx/modules/lot'
import { getUserData } from 'rdx/modules/auction'

import usePrevious from './usePrevious'
import { requestInterval } from 'lib/timers'
import { bid_extension_time } from 'lib/constants'

const useLotRefresher = ({ runOnInit=false, params={} }={}) => {
  const siteinfo = useSelector(state => state.siteinfo.data)
  const timestamp = useSelector(state => state.lot.content.meta.timestamp)
  const status = useSelector(state => state.lot.content.meta.status)
  const time_since_lastbid = useSelector(state => state.lot.content.data.time_since_lastbid)
  const isAuthenticated = useSelector(state => state.auth.login.data.isAuthenticated)
  const prevTimestamp = usePrevious(timestamp)
  const dispatch = useDispatch()

  const [count, setCount] = useState(0)

  const catalog_mode = +params.auctionNumber === siteinfo.current_auction && siteinfo.auction_mode !== 5 ? 'current' : 'archive'
  const refreshInterval = 60

  const _refreshData = useCallback(
    () => {
      dispatch(getLot(params.auctionNumber, params.lotNumber))
      if (isAuthenticated) {
        // console.log('auth -> refresh user data')
        dispatch(getUserData())
      }
    },[dispatch, isAuthenticated, params.auctionNumber, params.lotNumber])

  const isInitialMount = useRef(true)

  // handles refresh on initial mount and/or on dependency changes
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      // console.log('*lot refresh (initial)')
      runOnInit && _refreshData('initial')
    } else {
      // refresh on dependency change (except on initial mount)
      // console.log('*lot refresh (dep-update)')
      _refreshData('dep-update')
    }
  }, [catalog_mode, siteinfo.auction_mode, siteinfo.auction_end_date, runOnInit, _refreshData])

  // const seconds_since_server_time = Math.trunc((timestamp - Date.now())/1000)
  const auction_server_seconds_left = Math.trunc((siteinfo.auction_end_date - timestamp)/1000)
  const resolved_auction_seconds_left = auction_server_seconds_left - count

  // handles refresh on interval
  useEffect(() => {
    // console.log('useLotRefresher-useEffect-count')
    // if initial request fails, try again after another standard interval and then every 10 intervals
    if (count === refreshInterval || (count === refreshInterval * 2) || (count > 0 && (count % (refreshInterval * 10) === 0))) {
      // console.log('*lot refresh (timer-based)')
      _refreshData('standard')
    } else if (siteinfo.auction_mode === 1 && ((resolved_auction_seconds_left === 25 && count >= 5) || resolved_auction_seconds_left === -4 && count >= 2)) {
      // mode 1 - active auction
      // refresh if 25 seconds is left in auction and it has been 5 or more seconds since last refresh (should guarantee refresh between 25-30 seconds left in auction)
      // also refresh if the auction period ended 4 seconds ago and it has been 2 or more seconds since last refresh (should guarantee refresh within 2-4 seconds after auction ends)
      // console.log('*lot refresh (less than 30 seconds remaining in general auction)')
      _refreshData('less-than-30-seconds-remaining-in-general-auction')
    } else if (siteinfo.auction_mode === 2 && (time_since_lastbid <= bid_extension_time) && ((count >= 30 && prevTimestamp === timestamp) || (bid_extension_time - (time_since_lastbid + count) === -3 && count >= 1))) {
      // mode 2 - closing mode
      // refresh if 30 seconds or more has passed since last refresh, or if lot closed 3 seconds ago and it has been 1 or more seconds since last refresh (should guarantee refresh within 1-3 seconds after lot period closes)
      // prevTimestamp check prevents duplicate refreshes
      // console.log('*lot refresh (mode 2 open - when lot time up or 30 seconds has passed):', count)
      _refreshData('mode-2-open-lot-time-up-or-30-seconds-passed')
    }
  }, [_refreshData, count, timestamp, refreshInterval, resolved_auction_seconds_left, siteinfo.auction_end_date, siteinfo.auction_mode, time_since_lastbid, prevTimestamp])


  // controls interval timing (only uses interval in current auction, archived lots don't need to refresh data)
  useEffect(() => {
    if (catalog_mode === 'current') {
      if (status === 1) {
        setCount(0)
      }
      const interval = new requestInterval(() => setCount(c => c + 1), 1000)
      return () => interval.clear()
    }
  }, [catalog_mode, status])

  return { elapsedSeconds: count }
}

export default useLotRefresher

