import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>Terms and Conditions</title>
        <link rel="canonical" href={props.location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>Terms and Conditions</h2>
      </div>

      <div className="terms-container">
        <blockquote>
          <p>
            <span className="dropcap text-bold">1</span>
            <span>All lots are offered for sale in USD ($) by Old World Auctions, either as owner or as agent for various Consignors. These CONDITIONS OF SALE, as set forth in this catalog, are the complete, and only, terms and conditions on which all property is offered for sale. By bidding at auction, the buyer agrees to be bound by these Conditions of Sale.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">2</span>
            <span>Bids must be received by 10:00 PM Eastern Time of the closing date of the auction. Bids may be changed at any time up to the close, but cannot be cancelled after the close.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">3</span>
            <span>In the case of identical high bids, preference goes to the first received. If any dispute arises, the auctioneer's decision shall be absolute and final.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">4</span>
            <span>The estimated price shown for each lot should be used only as a guide. Lots may end up selling for more or less than the estimated price. Bids must be placed in fixed bidding increments. Bids received, which are not in accordance with bidding increments, will be reduced to the next lower increment.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">5</span>
            <span>There is no obligation to sell items at less than the reserve price. Lot without a reserve are marked as "No Reserve" lots. Where reserves exist, they are no more than 75% of the low estimate. Reserves are kept confidential.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">6</span>
            <span>High bids will be reduced to the reserve, or to the increment above the next highest bid by a competing bidder, which ever is greater.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">7</span>
            <span>A 20% buyer&apos;s premium will be added to each lot with a hammer price below $5,000.  For lots with hammer prices equal to and above $5,000, a 15% buyer&apos;s premium will be added.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">8</span>
            <span>Invoices will be prepared within five business days following the auction close and you will receive email notification when your invoice is complete. Payment is due on receipt of the invoice. You must approve the invoice or contact us directly within three days of the receipt of the invoice. Invoices that are not acknowledged within three days may be cancelled and the sale rescinded, at the option of Old World Auctions. Invoices not paid within 30 days will incur a $25.00 additional charge, and may be rescinded, at the option of Old World Auctions. The items are shipped as soon as possible after receipt of your payment. We appreciate your prompt payment as this allows us to pay our consignors equally promptly.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">9</span>
            <span>Packaging, shipping and insurance is extra and will be added to the invoice. Unless specific instructions are received, Old World Auctions will determine the best method of packaging and shipment.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">10</span>
            <span>Virginia residents will be charged sales tax at the rate of 6%, unless we are provided a copy of a reseller's permit.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">11</span>
            <span>We warranty the authenticity and condition of each lot described in this catalog. Defects in lots offered for sale have been carefully noted. If a lot is found to be substantially non-conforming to its catalog description, thereby significantly reducing its value, the purchaser must notify Old World Auctions within one week of receipt. Please call for a Return Authorization prior to returning. No returns will be accepted for any reason after 30 days following the auction close date.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">12</span>
            <span>You must open and inspect your package immediately upon receipt. Any discrepancies, damage or missing items must be reported to us within 2 business days of receipt by you. All packaging materials must be retained until the discrepancy has been resolved. Failure to notify us within the two-day limit will nullify any claim by you.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">13</span>
            <span>Successful overseas bidders shall provide specific shipping and customs instructions when completing their invoice, including specific customs declarations, for any deliveries outside of the United States. You are solely responsible for, and agree to pay all local fees including, but not limited to, duty and customs charges, and brokerage fees. These fees are not collected by Old World Auctions and are in addition to the amounts on your invoice.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">14</span>
            <span>The internet being the fragile environment that it is, Old World Auctions cannot be held responsible for server down time, or your inability to access our site. Notifications sent by email may possibly take longer than expected, and cannot be counted on to inform you of an item's status. In the event of technical problems, we reserve the right to cancel, restart, or extend an auction as the circumstances may dictate.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">15</span>
            <span>These terms and conditions are binding upon you and may be amended by us at any time. You will be notified of such changes in writing or via email.</span>
          </p>
        </blockquote>
      </div>
    </div>
  )
}

export default content
