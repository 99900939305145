import { combineReducers } from 'redux';
//import multireducer from 'multireducer';
import { routerReducer as routing } from 'react-router-redux'

import login from './auth/login'
import registration from './auth/registration'
import process from './auth/process'
import contact from './auth/contact'

import lookupData from './admin/lookupData'

import admin_auctions from './admin/auctions'
import admin_auction from './admin/auction'
import admin_lots from './admin/lots'
import clipboard from './admin/clipboard'
import users from './admin/users'
import resolution from './admin/resolution'
import lot_actions from './admin/lot_actions'
import operations from './admin/operations'
import slidegroups from './admin/slidegroups'

import statsbasic from './admin/statsbasic'
import auction_summary from './admin/auction_summary'
import auction_summary_history from './admin/auction_summary_history'
import bidding_activity from './admin/bidding_activity'
import lot_performance from './admin/lot_performance'
import consignor_summary from './admin/consignor_summary'

import auctions from './info/auctions'
import testimonials from './info/testimonials'
import sitemap from './info/sitemap'

import articles_archive from './info/articles/archive'
import articles_recent from './info/articles/recent'
import article from './info/articles/article'

import ssr from './ssr'
import siteinfo from './siteinfo'
import slides from './slides'
import menus from './menus'
import userData from './userData'
import consignorStatus from './consignorStatus'
import lot from './lot'
import lots from './lots'
import auction from './auction'
import invoice from './invoice'
import invoices from './invoices'
import statement from './statement'
import statements from './statements'
import purchase from './purchase'
import purchases from './purchases'
import consignments from './consignments'
import bidhistory from './bidhistory'
import cookie from './cookie'

export default combineReducers({
  auth: combineReducers({
    login,
    registration,
    process,
    contact,
  }),
  admin: combineReducers({
    lookupData,
    auction: admin_auction,
    auctions: admin_auctions,
    lots: admin_lots,
    clipboard,
    users,
    resolution,
    lot_actions,
    operations,
    slidegroups,
    statsbasic,
    auction_summary,
    auction_summary_history,
    bidding_activity,
    lot_performance,
    consignor_summary,
  }),
  info: combineReducers({
    auctions,
    testimonials,
    sitemap,
    articles: combineReducers({
      archive: articles_archive,
      recent: articles_recent,
      article: article,
    }),
  }),
  ssr,
  siteinfo,
  slides,
  menus,
  userData,
  consignorStatus,
  lot,
  lots,
  auction,
  invoice,
  invoices,
  statement,
  statements,
  purchase,
  purchases,
  consignments,
  bidhistory,
  routing,
  cookie,
})
