import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

DataListHeader.propTypes = {
  metadata: PropTypes.object.isRequired,
  showHeader: PropTypes.bool,
  headerClass: PropTypes.string,
  useTable: PropTypes.bool,
  tableRowHeaderClass: PropTypes.string,
  handleSort: PropTypes.func,
  activeSortField: PropTypes.string,
  activeSortTable: PropTypes.string,
  activeSortDirection: PropTypes.string,
  headerColumnVisibleKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

DataListHeader.defaultProps = {
  showHeader: true,
  useTable: false,
  headerClass: '',
  tableRowHeaderClass: 'datalist-table-row-header',
  activeSortField: 'id',
  activeSortTable: '',
  activeSortDirection: '',
}

function DataListHeader({ showHeader, headerClass, metadata, useTable, handleSort, tableRowHeaderClass, activeSortField, activeSortTable, activeSortDirection, headerColumnVisibleKey }) {
  const sortDescArrow = <span className={"fa fa-fw fa-caret-down"} style={{ color: '#e67e22' }} />
  const sortAscArrow = <span className={"fa fa-fw fa-caret-up"} style={{ color: '#e67e22' }} />
  const resolvedHeaderClass = classNames(tableRowHeaderClass, headerClass)

  if (!useTable && !showHeader) {
    return null
  }

  if (!metadata || !metadata.columns) { return null}
  const rowdata = metadata.columns.map((col, i) => {
    if (typeof col.visibleFunc !== 'undefined') {
      if (typeof headerColumnVisibleKey === 'undefined' || !col.visibleFunc(headerColumnVisibleKey)) {
        return null
      }
    }
    const style = col.style ? col.style : {}
    const sortable = col.sortable === true
    const aClass = classNames({
      'sortable': sortable,
    })
    const sortIcon = activeSortField === col.field_name && (activeSortTable === col.source_table || activeSortTable === null) ? (activeSortDirection === 'desc' ? sortDescArrow : sortAscArrow) : null
    const header = <a className={aClass} onClick={sortable ? handleSort(col.source_table, col.field_name) : null}>{typeof col.display_name !== 'undefined' ? col.display_name : col.field_name}{sortIcon}</a>
    const keyval = col.name ? col.name : col.field_name
    if (useTable) {
      if (showHeader) {
        return <th key={'th-' + keyval} className={col.thClass} style={style}>{header}</th>
      } else {
        return <col key={'col-' + keyval} style={style} />
      }
    } else {
      return <div key={'div-' + keyval}>{col.display_name ? col.display_name : col.field_name}</div>
    }
  })

  let content

  if (useTable) {
    if (showHeader) {
      content = <thead><tr className={resolvedHeaderClass}>{rowdata}</tr></thead>
    } else {
      content = <colgroup>{rowdata}</colgroup>
    }
  } else {
    content = <div>{rowdata}</div>
  }

  return content
}

export default DataListHeader