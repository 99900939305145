import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'

import FieldBase from '../shared/FieldBase'
import FieldText from '../shared/FieldText'
import FieldCheckbox from '../shared/FieldCheckbox'
import InputGroupMultiple from '../shared/InputGroupMultiple'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'

import { makeOptions, makeOptionsBoolean } from 'lib/utility'
import { user_options, invoice_options } from 'lib/constants'
import StatusBox from '../shared/StatusBox'

FormUser.propTypes = {
  user: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  lookupData: PropTypes.object.isRequired,
  fieldUpdate: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  makeConsignor: PropTypes.func.isRequired,
  generateConsignorCode: PropTypes.func.isRequired,
}

function FormUser({ user, isNew, lookupData, fieldUpdate, saveUser, makeConsignor, generateConsignorCode }) {
  const _handleUpdate = (field, value) => {
    fieldUpdate(field, value, user.data.id)
  }

  const _handleLeaveField = (field, value) => {
    fieldUpdate(field, value, user.data.id)
  }

  const _saveUser = () => {
    saveUser(user.data.id, { isNew })
  }

  const _handleMakeConsignor = () => {
    makeConsignor(user.data.id)
  }

  const _handleGenerateConsignorCode = () => {
    generateConsignorCode(user.data.id)
  }

  const { data, validation, makingConsignor } = user

  let f_ConsignorInfo = '';
  if (data.consignor_status) {
    f_ConsignorInfo =
      <Panel defaultExpanded={ true }>
        <Panel.Heading>
          <Panel.Title toggle>
            Consignor Info
          </Panel.Title>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body>
            <FormRow>
              { makingConsignor ? <FieldText name="consignor_code" addonBefore="Code" groupClassName="col-lg-3" /> : <FieldText name="consignor_code" addonBefore="Code" groupClassName="col-lg-3" disabled={ true } /> }
              <FieldText name="consignor_serial" addonBefore="Count" groupClassName="col-lg-3" disabled={ true } displayWhenNull="no consigned items" />
              <FieldBase type="select" name="consignor_commission_structure_id" addonBefore="Commission Structure" groupClassName="col-lg-4" standalone={ true }>
                { makeOptions(lookupData.consignor_commission_schemes) }
              </FieldBase>
            </FormRow>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
  }

  let form = <form className="basic-form">
    <FusedFields data={data} errors={validation.required} onUpdate={_handleUpdate} onLeave={_handleLeaveField} classes='basic-form-contents'>
      <FormRow>
        <FieldText name="user_email" addonBefore="Email" groupClassName="col-lg-3" />
        <FieldText name="user_email2" addonBefore="Email2" groupClassName="col-lg-3" />
        <FieldText name="id" addonBefore="ID" groupClassName="col-lg-2" disabled={ true } />
        <FieldCheckbox name="user_active" checked={ data && data.user_active == 1 } label="Active" groupClassName="col-lg-1" />
        <FieldBase type="select" name="user_status" addonBefore="Status" groupClassName="col-lg-3" data-type="unsigned-int">
          { makeOptions(user_options.user_status_values, { showVal: true }) }
        </FieldBase>
      </FormRow>
      <FormRow>
        <FieldText name="user_username" addonBefore="Username" groupClassName="col-lg-3" />
        <FieldText name="user_password" addonBefore="Password" groupClassName="col-lg-3" />
        <FieldText name="meta_date_created" addonBefore="Created" groupClassName="col-lg-3" disabled={ true } />
        <FieldText name="meta_date_modified" addonBefore="Last Modified" groupClassName="col-lg-3" disabled={ true } />
      </FormRow>
      <FormRow>
        <div className="col-lg-6">
          <Panel defaultExpanded={ true }>
            <Panel.Heading>
              <Panel.Title toggle>
                Primary Contact Info
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <FormRow>
                  <InputGroupMultiple className="col-lg-12" addonBefore="Name">
                    <FieldBase type="rawinput" className="form-control" name="user_firstname" placeholder="First" maxLength={ 50 } style={{ width: '50%' }} />
                    <FieldBase type="rawinput" className="form-control" name="user_lastname" placeholder="Last" maxLength={ 50 } style={{ width: '50%' }} />
                  </InputGroupMultiple>
                </FormRow>
                <FormRow>
                  <FieldText name="primary_phone" addonBefore="Phone" groupClassName="col-lg-6" />
                  <FieldText name="primary_company" addonBefore="Company" groupClassName="col-lg-6" maxLength={ 50 } />
                </FormRow>
                <FormRow>
                  <FieldText name="primary_address_line1" addonBefore="Address1" groupClassName="col-lg-6" />
                  <FieldText name="primary_address_line2" addonBefore="Address2" groupClassName="col-lg-6" />
                </FormRow>
                <FormRow>
                  <FieldText name="primary_city" addonBefore="City" groupClassName="col-lg-6" />
                  <FieldText name="primary_state" addonBefore="State" groupClassName="col-lg-6" />
                </FormRow>
                <FormRow>
                  <FieldText name="primary_postalcode" addonBefore="Zip" groupClassName="col-lg-6" />
                  <FieldBase type='countryDropdown' name='primary_country' addonBefore='Country' groupClassName='col-md-6' />
                </FormRow>
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
        <div className="col-lg-6">
          <Panel defaultExpanded={ true }>
            <Panel.Heading>
              <Panel.Title toggle>
                Billing Info
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <FormRow>
                  <InputGroupMultiple className="col-lg-12" addonBefore="Name">
                    <FieldBase type="rawinput" className="form-control" name="bill_firstname" placeholder="First" maxLength={ 50 } style={{ width: '50%' }} />
                    <FieldBase type="rawinput" className="form-control" name="bill_lastname" placeholder="Last" maxLength={ 50 } style={{ width: '50%' }} />
                  </InputGroupMultiple>
                </FormRow>
                <FormRow>
                  <FieldText name="bill_phone" addonBefore="Phone" groupClassName="col-lg-6" />
                  <FieldText name="bill_company" addonBefore="Company" groupClassName="col-lg-6" maxLength={ 50 } />
                </FormRow>
                <FormRow>
                  <FieldText name="bill_address_line1" addonBefore="Address1" groupClassName="col-lg-6" />
                  <FieldText name="bill_address_line2" addonBefore="Address2" groupClassName="col-lg-6" />
                </FormRow>
                <FormRow>
                  <FieldText name="bill_city" addonBefore="City" groupClassName="col-lg-6" />
                  <FieldText name="bill_state" addonBefore="State" groupClassName="col-lg-6" />
                </FormRow>
                <FormRow>
                  <FieldText name="bill_postalcode" addonBefore="Zip" groupClassName="col-lg-6" />
                  <FieldBase type='countryDropdown' name='bill_country' addonBefore='Country' groupClassName='col-md-6' />
                </FormRow>
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
      </FormRow>
      <FormRow>
        <div className="col-lg-6">
          <Panel defaultExpanded={ true }>
            <Panel.Heading>
              <Panel.Title toggle>
                Shipping Info
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <FormRow>
                  <InputGroupMultiple className="col-lg-12" addonBefore="Name">
                    <FieldBase type="rawinput" className="form-control" name="ship_firstname" placeholder="First" maxLength={ 50 } style={{ width: '50%' }} />
                    <FieldBase type="rawinput" className="form-control" name="ship_lastname" placeholder="Last" maxLength={ 50 } style={{ width: '50%' }} />
                  </InputGroupMultiple>
                </FormRow>
                <FormRow>
                  <FieldText name="ship_phone" addonBefore="Phone" groupClassName="col-lg-6" />
                  <FieldText name="ship_company" addonBefore="Company" groupClassName="col-lg-6" maxLength={ 50 } />
                </FormRow>
                <FormRow>
                  <FieldText name="ship_address_line1" addonBefore="Address1" groupClassName="col-lg-6" />
                  <FieldText name="ship_address_line2" addonBefore="Address2" groupClassName="col-lg-6" />
                </FormRow>
                <FormRow>
                  <FieldText name="ship_city" addonBefore="City" groupClassName="col-lg-6" />
                  <FieldText name="ship_state" addonBefore="State" groupClassName="col-lg-6" />
                </FormRow>
                <FormRow>
                  <FieldText name="ship_postalcode" addonBefore="Zip" groupClassName="col-lg-6" />
                  <FieldBase type='countryDropdown' name='ship_country' addonBefore='Country' groupClassName='col-md-6' />
                </FormRow>
                <FormRow>
                  <FieldBase type="select" name="ship_carrier_preference" addonBefore="Carrier Preference" groupClassName="col-lg-6">
                    {makeOptions(data.ship_country === 'US' ? invoice_options.options_carrier_us : invoice_options.options_carrier_other)}
                  </FieldBase>
                  <FieldBase type="select" name='ship_customs_declaration' addonBefore="Customs" groupClassName='col-lg-6'>
                    {makeOptions(invoice_options.options_customs_declaration)}
                  </FieldBase>
                </FormRow>
                <FormRow>
                  <FieldBase type="select" name='options_delivery_signature' addonBefore='Signature Required' groupClassName='col-lg-6'>
                    {makeOptionsBoolean()}
                  </FieldBase>
                  <FieldBase type="select" name='options_insurance' addonBefore='Full Insurance' groupClassName='col-lg-6'>
                    {makeOptionsBoolean()}
                  </FieldBase>
                </FormRow>
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
        <div className="col-lg-6">
          <Panel defaultExpanded={ true }>
            <Panel.Heading>
              <Panel.Title toggle>
                Shipping Notes
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <FormRow>
                  <FieldBase type="textarea" name="ship_notes_admin" label="Admin Ship Notes" style={{ height: '130px' }} groupClassName="col-lg-12" maxLength={ 700 } />
                </FormRow>
                <FormRow>
                  <FieldBase type="textarea" name="ship_notes_user" label="User Ship Notes" style={{ height: '130px' }} groupClassName="col-lg-12" maxLength={ 700 } />
                </FormRow>
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
      </FormRow>
      <FormRow>
        <div className="col-lg-6">
          <Panel defaultExpanded={ true }>
            <Panel.Heading>
              <Panel.Title toggle>
                User Preferences and Feedback
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <FormRow>
                  <FieldBase type="select" name="user_referralsource" addonBefore="Referral Source" groupClassName="col-lg-12">
                    { makeOptions(user_options.user_referral_sources) }
                  </FieldBase>
                </FormRow>
                <FormRow>
                  <FieldCheckbox name="user_email_pref_auction_reminders" checked={ data && data.user_email_pref_auction_reminders == 1 } label="Auction Reminders (4x / auction)" groupClassName="col-lg-12" />
                </FormRow>
                <FormRow>
                  <FieldCheckbox name='user_email_pref_outbid_notices' checked={data.user_email_pref_outbid_notices == 1} label='Outbid Notices (real time)' groupClassName='col-lg-12' />
                </FormRow>
                <FormRow>
                  <FieldCheckbox name='user_email_pref_price_reductions' checked={data.user_email_pref_price_reductions == 1} label='After-Auction Price Reductions (1x / auction)' groupClassName='col-lg-12' />
                </FormRow>
                <FormRow>
                  <FieldCheckbox name='user_email_pref_newsletter' checked={data.user_email_pref_newsletter == 1} label='Newsletters (8x / year) - info on antique maps, collecting tips, and events in the map community' groupClassName='col-lg-12' />
                </FormRow>

              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
        <div className="col-lg-6">
          <Panel defaultExpanded={ true }>
            <Panel.Heading>
              <Panel.Title toggle>
                General Notes/Comments
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <FormRow>
                  <FieldBase type="textarea" name="general_notes_admin" label="Admin Notes" style={{ height: '130px' }} groupClassName="col-lg-12" maxLength={ 700 } />
                </FormRow>
                <FormRow>
                  <FieldBase type="textarea" name="user_comments" label="User Comments" style={{ height: '130px' }} groupClassName="col-lg-12" maxLength={ 700 } />
                </FormRow>
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
      </FormRow>
      { data.consignor_status ? <hr /> : '' }
      { f_ConsignorInfo }
    </FusedFields>
    <div className="flex-standard-container form-action-container top-separator-border">
      <div className="flex-group flex-group-left">
        <button type="button" className="btn btn-u btn-u-dark-blue" onClick={ _saveUser }><span className={ isNew ? 'fa fa-plus' : 'fa fa-upload' } />{ isNew ? ' Add' : ' Update' }</button>
      </div>
      <div className="flex-group flex-group-center">
        <StatusBox data={user.update} size="micro" childSize="micro" allowClose={true} />
        <StatusBox data={user.generateConsignorCode} size="micro" />
      </div>
      <div className="flex-group flex-group-right">
        { !data.consignor_status && <button type="button" className='btn-u btn-u-dark-blue btn btn-default pull-right' onClick={ _handleMakeConsignor }>{ 'Make this user a Consignor' }</button> }
        { makingConsignor && <button type="button" className='btn-u btn-u-dark-blue btn btn-default pull-right' onClick={ _handleGenerateConsignorCode }>{ 'Auto Generate Consignor Code' }</button> }
      </div>
    </div>
  </form>

  return <Panel className="panel-basic panel-basic-admin rounded-bottom border-top-none">
    <Panel.Body>
      { form }
    </Panel.Body>
  </Panel>
}

export default FormUser
