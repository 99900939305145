import React from 'react'
import PropTypes from 'prop-types'

import FormRow from './FormRow'
import StatusBox from './StatusBox'

ConfirmBox.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  resultType: PropTypes.string,
  resultFormat: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  hide_action_buttons: PropTypes.bool,
  confirm_button_show: PropTypes.bool,
  confirm_button_action: function(props, propName, componentName) {
      if ((props['confirm_button_show'] == true && props['hide_action_buttons'] == false && (props[propName] == undefined || typeof(props[propName]) != 'function'))) {
          return new Error(
              'confirm_button_action function prop is missing!'
          );
      }
  },
  confirm_button_text: PropTypes.string,
  close_button_text: PropTypes.string,
  close_button_action: function(props, propName, componentName) {
      if ((props['hide_action_buttons'] == false && (props[propName] == undefined || typeof(props[propName]) != 'function'))) {
          return new Error(
              'close_button_action function prop is missing!'
          );
      }
  },
  style: PropTypes.object,
  footerTopMargin: PropTypes.string,
  showStatusCodeText: PropTypes.bool,
}

ConfirmBox.defaultProps = {
  data: { status: 0 },
  resultType: 'standard', //standard, persist, none
  resultFormat: 'header',
  style: {},
  title: 'Confirm',
  titleIcon: 'fa fa-cog',
  hide_action_buttons: false,
  confirm_button_show: true,
  confirm_button_text: 'Confirm',
  close_button_text: 'Cancel',
  footerTopMargin: '15px',
  showStatusCodeText: true,
}


function ConfirmBox({ children, title, titleIcon, data, resultType, resultFormat, style, hide_action_buttons, confirm_button_show, confirm_button_text, confirm_button_action, close_button_action, close_button_text, footerTopMargin, showStatusCodeText }) {
  if (React.Children.count(children) > 1) {
    return <div style={{ padding: '20px' }}>Too many child elements<br />Please notify an administrator</div>
  }
  // Status Codes
  // 0 - no result (pre-action)
  // 1 - success
  // 2 - fail
  // 3 - warning
  // 5 - exception

  const placeholder = <div style={{ paddingTop: '20px' }}>Confirm or Cancel Action</div>
  const actual_close_button_text = data && data.status === 1 ? 'Close' : close_button_text

  const confirmButton = <a className="btn-u btn-u-sea pull-right" onClick={confirm_button_action}>{confirm_button_text}</a>
  const buttonBar = <FormRow>
    <div className="col-md-12">
      <a className="btn-u btn-u-red" onClick={close_button_action}>{actual_close_button_text}</a>
      {data && data.status === 1 || !confirm_button_show ? false : confirmButton}
    </div>
  </FormRow>

  return (
    <div className="confirm-box" style={style}>
      <div className="confirm-box-header">
        <h2><i className={titleIcon}></i>{title}</h2>
      </div>
      <div className="confirm-box-content">
        {React.Children.count(children) === 0 ? placeholder : children}
        {!hide_action_buttons && <div style={{marginTop: footerTopMargin}}>
          {/* ZZZ - move StatusBox out of here into calling component for better flexibility? */}
          {resultType !== 'none' && <StatusBox data={data} format={resultFormat} className="margin-bottom-20" persist={resultType === 'persist'} />}
          {data && data.processing === true ? null : buttonBar}
        </div>}
      </div>
    </div>
  )
}

export default ConfirmBox
