import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'
import { Scrollchor, easeOutQuad } from 'react-scrollchor'

const scrollAnimate = {
  offset: -40,
  duration: 400,
  easing: easeOutQuad,
}

// ZZZ - implement forgot-password page separately from login page
// ZZZ - implement page redirection for pages requiring authentication after login
function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>Frequently Asked Questions</title>
        <link rel="canonical" href={props.location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>Answers to Frequently Asked Questions</h2>
      </div>

      <div className="faq-link-container row">
        <div className="faq-link-column col-md-6 col-lg-6">
          <Scrollchor to="#registration" animate={scrollAnimate}>
            <div className="faq-link">
              <i className="fa fa-chevron-circle-right"></i>
              <span>Registration</span>
            </div>
          </Scrollchor>
          <Scrollchor to="#finding-information" animate={scrollAnimate}>
            <div className="faq-link">
              <i className="fa fa-chevron-circle-right"></i>
              <span>Finding Information and Items</span>
            </div>
          </Scrollchor>
          <Scrollchor to="#bidding" animate={scrollAnimate}>
            <div className="faq-link">
              <i className="fa fa-chevron-circle-right"></i>
              <span>Bidding</span>
            </div>
          </Scrollchor>
        </div>
        <div className="faq-link-column col-md-6 col-lg-6">
          <Scrollchor to="#auction-closing" animate={scrollAnimate}>
            <div className="faq-link">
              <i className="fa fa-chevron-circle-right"></i>
              <span>Auction Closing</span>
            </div>
          </Scrollchor>
          <Scrollchor to="#after-auction" animate={scrollAnimate}>
            <div className="faq-link">
              <i className="fa fa-chevron-circle-right"></i>
              <span>After the Auction</span>
            </div>
          </Scrollchor>
          <Scrollchor to="#consignment" animate={scrollAnimate}>
            <div className="faq-link">
              <i className="fa fa-chevron-circle-right"></i>
              <span>Consignment</span>
            </div>
          </Scrollchor>
        </div>
      </div>


      <div className="question-container" id="registration">
        <div className="headline">
          <h4>REGISTRATION</h4>
        </div>
        <blockquote>
          <h4>Do I need to register again if I have bid in previous auctions?</h4>
          <span>No! You should not register again. Use the <Link to={'/login'}>Login Page</Link> where you will only need to provide your user name and password. If you have forgotten your user name or password, please <Link to={'/forgot-password'}>click here</Link>.</span>
        </blockquote>
        <blockquote>
          <h4>What do I do if I forget my user name or password?</h4>
          <span>We have a program to help you recover your user name and password. Click <Link to={'/forgot-password'}>here</Link> for help.</span>
        </blockquote>
        <blockquote>
          <h4>How do I update my email, shipping address, phone number or other personal information?</h4>
          <span>You can easily change any personal information, email address, provide special shipping instructions, etc. by logging in to your account.  <Link to={'/user/profile'}>Click here</Link>.</span>
        </blockquote>
        <blockquote>
          <h4>What if I submitted the registration form but didn't get a confirmation email?</h4>
          <span>Please first check your spam/junk mail folder for the confirmation email.  If you didn't get your confirmation email please contact us directly either by email info@oldworldauctions.com or by telephone 804-290-8090.</span>
        </blockquote>
      </div>

      <div className="question-container" id="finding-information">
        <div className="headline">
          <h4>FINDING INFORMATION AND ITEMS</h4>
        </div>
        <blockquote>
          <h4>How can I find a particular item?</h4>
          <span>When viewing the current auction catalog, you can browse using the geographical and subject categories on the left side of the page.  You can also use our basic or advanced search options near the top of the page to find items matching your specific criteria.</span>
        </blockquote>
        <blockquote>
          <h4>How can I find out more information about an item?</h4>
          <span>We are available to provide additional information and condition reports. You may contact us by telephone at (804) 290-8090 or email info@oldworldauctions.com. Please be sure to obtain this information prior to the closing date. We have a small staff and are all fully committed to receiving telephone bids on the last day of the sale.</span>
        </blockquote>
        <blockquote>
          <h4>How can I find the items I have bid on?</h4>
          <span>Once you have placed bids you can monitor your bidding status in the <Link to={'/user/status'}>Bid Status page</Link>.</span>
        </blockquote>
      </div>

      <div className="question-container" id="bidding">
        <div className="headline">
          <h4>BIDDING</h4>
        </div>
        <blockquote>
          <h4>Why was the next bid increment larger than I expected after I placed my bid?</h4>
          <span>The bid increment gets larger as the bid price increases. Please refer to the bidding increments table that is listed on each lot detail page.</span>
        </blockquote>
        <blockquote>
          <h4>Why did my bid jump so high?</h4>
          <span>If you place a proxy bid that is higher than the next bid increment, there are several instances in which the bid will be increased above the next bid increment:
            <div className="shadow-wrapper">
              <div className="box-shadow shadow-effect-1">
                <table className="table table-striped">
                  <colgroup>
                    <col style={{width: '50%'}} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>Scenario A:<br />You place a proxy bid and there are no other bidders.</td>
                      <td>Proxy bids only take effect if they are above the reserve, because the reserve is the lowest price for which the consignor is willing to sell the item. Any bids that are equal to or below the reserve are entered at face value. If you set a proxy bid above the reserve, your bid will be set at the reserve value. In this scenario, you will be the current high bidder.</td>
                    </tr>
                    <tr>
                      <td>Scenario B:<br />You place a proxy bid, and another bidder has already placed a proxy bid that is lower than your proxy bid.</td>
                      <td>Your bid will then be increased to one increment over his/her maximum bid. In this scenario, you will be the current high bidder.</td>
                    </tr>
                    <tr>
                      <td>Scenario C:<br />You place a proxy bid, and another bidder has already placed a higher proxy bid.</td>
                      <td>The other bidder’s proxy bid will come into effect, and his/her bid will be increased to one increment over your proxy bid. In this scenario, you will NOT be the current high bidder, and may place a higher bid if you choose.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </span>
        </blockquote>
        <blockquote>
          <h4>I bid against myself to raise my maximum and the high bid increased. Why?</h4>
          <span>The system will not allow you to bid against yourself. However, there are two instances where it may appear that you have bid against yourself. First, if another bidder has placed a bid that ties with your bid and then you place a higher maximum bid, the system will increase your bid by one increment. Second, if your first bid is under the reserve, your next bid will be accepted at face value up to the reserve amount, because proxy bids are only in effect above the reserve price.</span>
        </blockquote>
        <blockquote>
          <h4>Why do I get a message telling me I've been outbid when the current high bid is the amount of my bid?</h4>
          <span>This will happen if another bidder has placed a proxy (maximum) bid for exactly the same amount that you bid. Because the other bid was received first, it becomes the current high bid. You must place a higher bid in order to become the high bidder.</span>
        </blockquote>
        <blockquote>
          <h4>Why doesn't the website show who has the high bid?</h4>
          <span>We protect the confidentiality of all bidders. This prevents collusion among bidders and protects our consignors. You can monitor the status of all your bids on the <Link to={'/user/status'}>Bid Status page</Link>.</span>
        </blockquote>
        <blockquote>
          <h4>I bid on an item and got the message that my bid is under reserve. But there isn't a reserve shown. Why?</h4>
          <span>Reserves are kept confidential with the consignor. All items have a reserve unless they are marked as a "No Reserve" lot. Reserves are set between 50% and 75% of the low estimate.</span>
        </blockquote>
        <blockquote>
          <h4>What is the QwikBid button for and why don't I always see it?</h4>
          <span>Our QwikBid feature is only available on your <Link to={'/user/status'}>Bid Status page</Link> and lets you quickly place another bid if your last bid is not the high bid. It will only appear after you bid on an item and you are <b><u>not</u></b> the current high bidder. Clicking the QwikBid button will increase your bid one increment above the current high bid.
        From the Watch List, the QwikBid button will place the bid at the published starting amount if no bids exist.
        A few important notes regarding QwikBid:
        -- QwikBid only works when you are <b><u>not</u></b> the high bidder.
        -- You cannot use QwikBid to place a higher 'maximum' bid if you are the high bidder. You must place this type bid in the regular way, e.g. either from the lot detail page or from the Bid Status page.</span>
        </blockquote>
        <blockquote>
          <h4>How can I cancel a bid?</h4>
          <span>If you have placed a bid in error you will need to contact our office either by email info@oldworldauctions.com or telephone 804-290-8090.</span>
        </blockquote>
        <blockquote>
          <h4>Can I monitor lots without having to bid on them?</h4>
          <span>Yes, you may add items to your Watch List directly from the catalog listing or any lot detail page.  Simply click the "Add to Watch List" button.  You can then view your Watch List from your Bid Status page. You may add and delete lots from your Watch List at anytime. If you bid on an item on your Watch List, that lot will automatically be removed from the Watch List and added to your Bid Status list.</span>
        </blockquote>
      </div>

      <div className="question-container" id="auction-closing">
        <div className="headline">
          <h4>AUCTION CLOSING</h4><h5>("10-Minute Rule" applies to individual lots)</h5>
        </div>
        <blockquote>
          <h4>Why are some lots still active after the auction closes?</h4>
          <span>The auction begins closing 10:00 PM Eastern Time when all bidding activity has stopped for 10 minutes. This simulates what may happen in a floor auction in which the auctioneer signals the impending close of an auction, but does not bring the final hammer down until there are no more bids. This eliminates the problem of sniping often associated with internet auctions.
        Beginning at 10:00 PM the system will close any lots that have not had any bids within the last 10 minutes.  If there has been a bid in the past 10 minutes, that individual lot will remain open until there have been no bids received on that lot for 10 minutes. You may check your status at any time on your Bid Status page and observe when each item closes in real time.
        We strongly suggest that you simply enter your maximum bid early and let our Proxy system competitively enter your bids. This way you are relieved of closely monitoring the auction with the chance of losing the item. We get heavy traffic on the website and on the telephone at the close of the auction. For this reason some lots in the auction may not close for as much as an hour after the auction begins closing.</span>
        </blockquote>
      </div>

      <div className="question-container" id="after-auction">
        <div className="headline">
          <h4>AFTER THE AUCTION</h4>
        </div>
        <blockquote>
          <h4>I had the high bid at the closing time, but was then outbid. How can this happen?</h4>
          <span>The auction does not close immediately at the designated closing time; it begins closing at that time and continues until no bids have been received for 10 minutes. Therefore you may be outbid on a lot that remains open after the auction closing time per the 10-minute rule. (See above for more details on the 10-Minute Rule.)
        We are not just an "Internet Only" auction house. We accept bids from a wide variety of sources: phone, email and our website. Although we always try our very best to enter bids immediately; we simply can not keep up with the flood of bids to be entered near the end of the auction. Always bid your highest, most competitive bid if you are serious about winning! Your bid will always be reduced to one increment over the next highest bid. See Bidding section above for more information.</span>
        </blockquote>
        <blockquote>
          <h4>How do I find out what I won after the close of the auction?</h4>
          <span>You can always check the status of your bids on the <Link to={'/user/status'}>Bid Status page</Link>. After the closing time, your Bid Status page will show items that are still active under the 10-Minute Rule at the top of your list, and items that are already closed will be listed below. Items for which you are the highest bidder <b><u>and</u></b> for which your bid is above the reserve will be shown in green.  Items for which you are the highest bidder <b><u>but</u></b> for which your bid is below the reserve will be shown in yellow.  Yellow items are not considered sold.  If you wish to purchase a lot in yellow that is below the reserve, you may "Buy it Now" at the reserve price using the button on the Bid Status page or on the lot detail page.  If an item is shown in red, you are not the high bidder.  Please note that for lots that are not sold and have under-reserve bids, the consignor may choose to accept the under-reserve offer.  If your under-reserve offer is accepted by the consignor you will be notified by email.</span>
        </blockquote>
        <blockquote>
          <h4>I am the high bidder on an item, but it says my bid is below reserve. What happens now?</h4>
          <span>If you are still interested in acquiring the lot, you have two options.  You may "Buy it Now" at the reserve price using the button on the Bid Status page or on the lot detail page.  This is the only way to guarantee that you will be able to purchase the lot.  If you do not decide to purchase the lot at the reserve price, you may wait to see if the consignor accepts your under-reserve offer.  Typically within 48 hours of the close of the auction we contact all our consignors and give them the option of accepting offers below their reserves. If accepted, the item status will change to sold and will be shown in green on your Bid Status page, and you will receive an email notification that the consignor has accepted your offer. Please note that consignors are under no obligation to accept bids below the reserve. Please remember that after the auction closes, all unsold lots, including those with under-reserve bids, are available for immediate sale at the reserve price on a first come basis, and many lots sell at the full reserve price before consignors review their under-reserve offers. <b>The under-reserve bidder does not have priority in purchasing the lot.</b> Please contact us via email if you are the under-reserve high bidder on an item that you do not desire to purchase.</span>
        </blockquote>
        <blockquote>
          <h4>What is the Buyer&apos;s Premium?</h4>
          <span>A 20% buyer&apos;s premium will be added to each lot with a hammer price below $5,000.  For lots with hammer prices equal to and above $5,000, a 15% buyer&apos;s premium will be added.</span>
        </blockquote>
        <blockquote>
          <h4>Can I get high quality images of the maps I purchase at auction?</h4>
          <span>Yes, we have a digital image service available to the winning bidder of each lot.  The cost is $5 per lot, and the images will be sent to you electronically. You may add this option to your <Link to={'/user/invoices'}>invoice</Link>, or you may contact Jon Dotson at jon(at)oldworldauctions.com for further details.</span>
        </blockquote>
        <blockquote>
          <h4>How can I pay for my purchase?</h4>
          <span>We accept payment by all major credit cards, check, or bank transfer. Your invoice will be posted in the your account as soon as we have calculated the shipping charges. This normally takes up to five business days after the close of the auction. You will receive an email asking you to review and approve the shipping address and payment method on your invoice. We will not process the invoice until we receive your approval.</span>
        </blockquote>
        <blockquote>
          <h4>When will my items ship?</h4>
          <span>Your items will be shipped as soon as you have approved your invoice and we receive payment. If you need to request expedited shipping, have special instructions, or would like us to hold your shipment until a later date, please contact us immediately following the auction. Once the items have been shipped you will receive an email from the shipping carrier with your tracking information and we will post the ship date on your <Link to={'/user/invoices'}>invoice</Link>.</span>
        </blockquote>
        <blockquote>
          <h4>How will my items be shipped and what is the cost?</h4>
          <span>Unless we receive special instructions we will determine the best method of packaging and shipment. We generally ship via USPS, FedEx, UPS or DHL depending on the destination and value of the package. You will be charged the actual costs for packaging, insurance and shipping. You will have the opportunity to review the shipping details for your purchases directly on your invoice, including the carrier, insurance, and delivery signature. If you want to change any of these options, you can do so directly on your invoice and the OWA staff will make any necessary changes to your shipping cost. Once the items have been shipped you will receive an email from the shipping carrier with your tracking information and we will post the ship date on your <Link to={'/user/invoices'}>invoice</Link>.</span>
        </blockquote>
        <blockquote>
          <h4>If I purchase something in the auction and decide afterward that I don't want it, can I return it?</h4>
          <span>Bidding at auction is a binding contract to purchase the item. You are not purchasing on an approval basis. Therefore all sales are final. A sale may be rescinded only if it is determined that the lot is significantly different than described in the catalog. We offer a 100% money-back guarantee for any lot that differs significantly from the catalog description. If you have concerns about a lot you purchased from OWA, please contact us within 48 hours of receiving the item and we will be happy to work with you to resolve the issue.</span>
        </blockquote>
        <blockquote>
          <h4>When are the prices realized available?</h4>
          <span>Immediately following the auction items that are sold will be marked as such and the winning bid for those items will be shown in the online catalog (exclusive of the 15% Buyer's Premium). Those items that are unsold will be listed at the consignor's reserve price and can be purchased by using the "Buy it Now" button on the Bid Status page or on the lot detail page.</span>
        </blockquote>
        <blockquote>
          <h4>What happens to lots that don't sell during the auction?</h4>
          <span>Lots that were not sold during the auction are available for sale at the consignor's reserve immediately following the auction close. These lots may be purchased by clicking the "Buy it Now" button on the Bid Status page or on the lot detail page. These lots are available on a first come basis. Please keep in mind that under reserve bidders do not have a hold on the lot or priority in purchasing the lot - anyone can purchase it at the reserve following the close of the auction.</span>
        </blockquote>
      </div>

      <div className="question-container" id="consignment">
        <div className="headline">
          <h4>CONSIGNMENT</h4>
        </div>
        <blockquote>
          <h4>I have items I would like to consign. How should I proceed?</h4>
          <span>Old World Auctions welcomes your inquiries and is happy to provide a free preliminary evaluation. For more information, please <Link to={'/resources/how-to-consign'}>click here</Link> to go to our page on How to Consign.</span>
        </blockquote>
      </div>
    </div>
  )
}

export default content
