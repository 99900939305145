import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import classNames from 'classnames'

import DataListPager from './DataListPager'
import DataListHeader from './DataListHeader'

DataListContainer.propTypes = {
  children: PropTypes.node.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalItemCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  sortKey: PropTypes.string,
  showCount: PropTypes.bool,
  showHeader: PropTypes.bool,
  headerClass: PropTypes.string,
  headerColumnVisibleKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  showTopPagingControl: PropTypes.bool,
  showBottomPagingControl: PropTypes.bool,
  pagingControlSize: PropTypes.string,
  useTable: PropTypes.bool,
  tableClass: PropTypes.string,
  tableLayoutFixed: PropTypes.bool,
  tableStyle: PropTypes.object,
  metadata: PropTypes.object,
  errorCondition: PropTypes.bool,
  errorMessage: PropTypes.string,
  emptyMessage: PropTypes.string,
  className: PropTypes.string,
  itemDisplayName: PropTypes.string,
  itemDisplayNamePlural: PropTypes.string,
}

DataListContainer.defaultProps = {
  pageSize: 20,
  metadata: {},
  showCount: true,
  showHeader: true,
  showTopPagingControl: true,
  showBottomPagingControl: true,
  pagingControlSize: 'normal',
  useTable: false,
  tableClass: 'datalist-table-standard',
  tableLayoutFixed: true,
  tableStyle: {},
  errorCondition: false,
  errorMessage: 'An error occurred',
  emptyMessage: '-- No data present --',
  location: {},
  sortKey: 'db',
  itemDisplayName: 'item',
}

function DataListContainer({ location, sortKey, children, isLoading, errorCondition, errorMessage, emptyMessage, showHeader, headerClass, headerColumnVisibleKey, showTopPagingControl, showBottomPagingControl, pagingControlSize, showCount, useTable, tableClass, tableLayoutFixed, tableStyle, pageSize, totalItemCount, metadata, className, itemDisplayName, itemDisplayNamePlural }) {
  const handleSort = (sort_table, sort_field) => (e) => {
    const activeSortTable = location.query && typeof location.query['sort_table_' + sortKey] !== 'undefined' && location.query['sort_table_' + sortKey] !== '' ? location.query['sort_table_' + sortKey] : null
    const activeSortField = location.query && typeof location.query['sort_field_' + sortKey] !== 'undefined' && location.query['sort_field_' + sortKey] !== '' ? location.query['sort_field_' + sortKey] : null
    const newSortTable = typeof sort_table !== 'undefined' && sort_table !== '' ? sort_table : null
    const newSortField = typeof sort_field !== 'undefined' && sort_field !== '' ? sort_field : null

    let new_query = Object.assign({}, location.query)
    delete new_query.page

    if (newSortField !== activeSortField || newSortTable !== activeSortTable) {
      // change sort and set to ascending
      if (newSortTable) {
        new_query['sort_table_' + sortKey] = newSortTable
      } else {
        delete new_query['sort_table_' + sortKey]
      }
      new_query['sort_field_' + sortKey] = newSortField
      new_query['sort_direction_' + sortKey] = 'asc'
    } else {
      // cycle sort direction
      if (typeof new_query['sort_direction_' + sortKey] === 'undefined') {
        new_query['sort_direction_' + sortKey] = 'asc'
      } else if (new_query['sort_direction_' + sortKey].toLowerCase() === 'asc') {
        new_query['sort_direction_' + sortKey] = 'desc'
      } else if (new_query['sort_direction_' + sortKey].toLowerCase() === 'desc') {
        delete new_query['sort_table_' + sortKey]
        delete new_query['sort_field_' + sortKey]
        delete new_query['sort_direction_' + sortKey]
      }
    }
    browserHistory.push({ ...location, query: new_query })
  }

  const page = location.query && location.query !== null && location.query.page ? parseInt(location.query.page) : 1
  const activeSortField = location.query && typeof location.query['sort_field_' + sortKey] !== 'undefined' ? location.query['sort_field_' + sortKey] : null
  const activeSortTable = location.query && typeof location.query['sort_table_' + sortKey] !== 'undefined' && location.query['sort_table_' + sortKey] !== '' ? location.query['sort_table_' + sortKey] : null
  const activeSortDirection = location.query && typeof location.query['sort_direction_' + sortKey] !== 'undefined' ? (location.query['sort_direction_' + sortKey].toLowerCase() === 'desc' ? 'desc' : '') : ''
  const tableLayout = tableLayoutFixed ? 'datalist-table-fixed' : null

  const finalTableClass = classNames('datalist-table', tableLayout, tableClass)
  const finalTableStyle = {
    ...(metadata.defaults && metadata.defaults.tableStyle),
    ...tableStyle,
  }

  if (errorCondition) { return <div className="datalist-wrapper"><div className={ 'datalist-status-box error' }><div className={ 'error' }><span className={ 'fa fa-exclamation-triangle' } />{ 'There was a problem trying to fetch the data (' + errorMessage + ')' }</div></div></div> }
  // if (totalItemCount === 0) { return <div style={{fontSize: '16px'}}>{emptyMessage}</div> }

  const maxPage = Math.max(Math.ceil(totalItemCount / pageSize), 1)
  if (page > maxPage) {
    return <div className="datalist-wrapper"><div className={ 'datalist-status-box error' }><div className={ 'caution' }><span className={ 'fa fa-exclamation-triangle' } />Page out of bounds</div></div></div>
  }

  let content = null
  if (useTable) {
    content =
      <table className={finalTableClass} style={finalTableStyle}>
        <DataListHeader showHeader={showHeader} headerClass={headerClass} metadata={metadata} useTable={useTable} handleSort={handleSort} activeSortTable={activeSortTable} activeSortField={activeSortField} activeSortDirection={activeSortDirection} headerColumnVisibleKey={headerColumnVisibleKey} />
        {children}
      </table>
  } else {
    content =
      <div className={className}>
        <div className="datalist-content">
          <div className="datalist-row-container">
            {children}
          </div>
        </div>
      </div>
  }

  return <div className="datalist-wrapper" style={{'opacity': (isLoading ? '0.2' : '1')}}>
    { showTopPagingControl ? <DataListPager pagingControlSize={pagingControlSize} page={page} maxPage={maxPage} totalItemCount={totalItemCount} showCount={showCount} route_pathname={location.pathname} route_query={location.query} itemDisplayName={itemDisplayName} itemDisplayNamePlural={itemDisplayNamePlural} /> : null }
    <div style={{position: 'relative'}}>
      {isLoading ? <div style={{position: 'absolute', margin: 'auto', width: '400px', height: '100px', top: '10px', left: '0', right: '0', fontSize: '50px', fontWeight: '900', zIndex: '1000'}}>Loading Data...</div> : null}
      {content}
      {totalItemCount === 0 && <div style={{ fontSize: '16px' }}>{emptyMessage}</div>}
    </div>
    { showBottomPagingControl ? <DataListPager pagingControlSize={pagingControlSize} page={page} maxPage={maxPage} totalItemCount={totalItemCount} showCount={showCount} route_pathname={location.pathname} route_query={location.query} position='bottom' itemDisplayName={itemDisplayName} itemDisplayNamePlural={itemDisplayNamePlural} /> : null }
  </div>
}

export default DataListContainer