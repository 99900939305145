import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

// import LotTimeRemaining from './components/LotTimeRemaining'
import WatchListToggle from './components/WatchListToggle'

import { round, formatMoneyInteger } from 'lib/utility'
import { resolveLotDisplayInfo } from 'lib/helpers'

LotSummary.propTypes = {
  data: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  catalog_mode: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userDataInit: PropTypes.bool.isRequired,
  elapsedSeconds: PropTypes.number,
  auxData1: PropTypes.object,
  auxData2: PropTypes.object,
  toggleWatch: PropTypes.func,
}

LotSummary.defaultProps = {
  data: {},
  elapsedSeconds: 0,
}

function LotSummary({ data, siteinfo, catalog_mode, isAuthenticated, userDataInit, elapsedSeconds, auxData1, auxData2, toggleWatch }) {
  const user_bid = auxData1
  const user_watch = auxData2

  const is_high_bidder = user_bid && user_bid.is_high_bidder === 1 ? true : false
  const estimate_element = <h5>Estimate: {formatMoneyInteger(data.price_estimate_low)} - {formatMoneyInteger(data.price_estimate_high)}</h5>

  const { display_mode } = resolveLotDisplayInfo({ catalog_mode, auction_mode: siteinfo.auction_mode, data, elapsedSeconds })
  const button_text = display_mode === 1 ? 'Bid Now' : (display_mode === 2 ? 'Buy Now' : (display_mode === 3 ? 'WITHDRAWN' : 'Lot Details'))
  const button_color = display_mode === 3 ? 'warning' : 'darkred'
  const button_suffix_icon = display_mode === 3 ? null : <span className="fa-fw fa fa-arrow-right" />
  const button_viewlot = <button className={`btn btn-u btn-u-${button_color}`} style={{ fontWeight: 'bold' }}>{button_text} {button_suffix_icon}</button>
  return <div className="row auction-lot-row">
    <div className="col-sm-12 sm-margin-bottom-40">
      <div className="funny-boxes funny-boxes-left-green box-shadow shadow-effect-1 lot-summary">
        <div className="row">
          <div className="col-sm-3 sm-margin-bottom-10 funny-boxes-img">
            <Link to={'/catalog/lot/' + data.auction_id + '/' + data.lot_number}><img className="img-responsive" src={__DYNAMIC_IMAGES_RELATIVE_BASE_URL__ + "auctions/" + data.auction_id + "/lots/" + data.auction_id + "-" + String(data.lot_number).padStart(3, "0") + "A.jpg"} alt=""/></Link>
          </div>
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-8">
                <h2 style={{fontWeight: 'bold', borderBottom: '1px solid #AAA', lineHeight: '23px'}}><Link to={'/catalog/lot/' + data.auction_id + '/' + data.lot_number}>{ catalog_mode === 'archive' ? 'Auction ' + data.auction_id + ',' : null } Lot {data.lot_number}</Link><WatchListToggle lot_id={data.id} lot_number={data.lot_number} hide={catalog_mode !== 'current' || !userDataInit || !!user_bid || !isAuthenticated || data.status_withdrawn === 1} user_watch={user_watch} toggleWatch={toggleWatch} /></h2>
                {data.desc_subject && <h4 style={{fontWeight: 'bold'}}>Subject: {data.desc_subject}</h4>}
                <h5>{data.creator.length > 2 ? data.creator : null}</h5>
                <h4 style={{fontWeight: 'bold'}}><Link to={'/catalog/lot/' + data.auction_id + '/' + data.lot_number} style={{color: '#555', textDecoration: 'none'}}>{data.desc_title ? (data.desc_title.length <= 400 ? data.desc_title : data.desc_title.substring(0,150) + '...') : ''}, {data.desc_period}</Link></h4>
                <h5>Size: {data.desc_width ? data.desc_width + ' x ' + data.desc_height + ' inches (' + round(data.desc_width * 2.54, 1) + ' x ' + round(data.desc_height * 2.54, 1) + ' cm)' : 'See Description'}</h5>
                {estimate_element}
                <h5 className="color-red" style={{fontWeight: 'bold'}}>{data.featured_text}</h5>
                {display_mode === 0 && <h5 className="color-red" style={{fontWeight: 'bold'}}>{data.reserve_met === 1 && data.price_highbid ? 'Sold for: ' + formatMoneyInteger(data.price_highbid) : 'Unsold'}</h5>}
                {display_mode === 0 && <h5 style={{fontWeight: 'bold'}}>Closed on {data.date_closed}</h5>}
              </div>
              <div className="col-sm-4">
                <div className="pull-right" style={{textAlign: 'right'}}>
                  <h2><Link to={'/catalog/lot/' + data.auction_id + '/' + data.lot_number}>{button_viewlot}</Link></h2>
                  {display_mode === 1 && <h4 style={{fontWeight: 'bold'}}>Current High Bid:<br />{formatMoneyInteger(data.price_highbid) || 0}</h4>}
                  {display_mode === 2 && <h4 style={{fontWeight: 'bold'}}>Price:<br />{(data.calc_price_reserve === 0 ? 'Pending...' : formatMoneyInteger(data.calc_price_reserve))}</h4>}
                  {display_mode === 1 && <h5 className="text-bold">{data.reserve_met ? <span className="color-green1">Reserve Met</span> : <span className="color-red">Reserve Not Met</span>}</h5>}
                  {/* <LotTimeRemaining auction_mode={siteinfo.auction_mode} display_mode={display_mode} elapsedSeconds={elapsedSeconds} time_since_lastbid={data.time_since_lastbid} /> */}
                  {display_mode === 0 && catalog_mode === 'current' && is_high_bidder && <h5 className="color-green1"><strong>You are the winner</strong></h5>}
                  {display_mode === 1 && is_high_bidder && <h5 className="color-green1"><strong><span className="fa-fw fa fa-check-circle"/>High Bidder</strong></h5>}
                  {display_mode === 1 && user_bid && <h5 className={is_high_bidder ? "color-green1" : "color-red"}><strong>Your Max Bid: {formatMoneyInteger(user_bid.current_max_bid)}</strong></h5>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default LotSummary