import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router'

import FusedFields from '../shared/fused-fields'

import { formatMoneyInteger, toMMSS } from 'lib/utility'
import { next_higher_bid } from 'lib/helper_auction'

// dictionary while this component is developed
// nature: the type of the LotCard (determines what info is displayed - bid_status, watchlist, consignor_status, resolution, lot_actions)

const blue = 'status-color-light-blue'
const green = 'status-color-green'
const yellow = 'status-color-yellow'
const red = 'status-color-red'

function finalizeValue(value, defaultDisplay="none") {
  return value ? formatMoneyInteger(value) : defaultDisplay
}

function config_pricebar({ leftLabel=null, leftValue=null, leftValueColor=blue, centerLabel=null, centerValue=null, centerValueColor=blue, rightLabel=null, rightValue=null, rightValueColor=blue }={}) {
  return {
    leftLabel,
    leftValue,
    leftValueColor,
    centerLabel,
    centerValue,
    centerValueColor,
    rightLabel,
    rightValue,
    rightValueColor,
  }
}

LotCard.propTypes = {
  nature: PropTypes.string.isRequired,
  view: PropTypes.string,
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  auction_mode: PropTypes.number.isRequired,
  elapsedSeconds: PropTypes.number.isRequired,
  bid_extension_time: PropTypes.number.isRequired,
  inputChange: PropTypes.func,
  placeBid: PropTypes.func,
  placeBuy: PropTypes.func,
  unwatch: PropTypes.func,
}

LotCard.defaultProps = {
  view: 'standard',
  data: {},
  options: {},
  className: '',
  style: {},
  inputChange: null,
  placeBid: null,
  placeBuy: null,
  unwatch: null,
}

function LotCard({ nature, view, data, options, className, style, auction_mode, elapsedSeconds, bid_extension_time, inputChange, placeBid, unwatch, promptAcceptUnderReserve, promptReduceReserve, ...props }) {
  const _placeQwikBid = () => {
    placeBid({ bid_type: 1, bid_source: nature === 'bid_status' ? 1 : 2, lot_id: data.lot_id, lot_number: data.lot_number, lot_title: data.desc_title, visible_high_bid: data.price_highbid })
  }

  const _placeBid = () => {
    if (data.max_bid > 0) {
      placeBid({ bid_type: 0, bid_source: nature === 'bid_status' ? 1 : 2, lot_id: data.lot_id, lot_number: data.lot_number, lot_title: data.desc_title, visible_high_bid: data.price_highbid, resolved_bid: data.max_bid })
    }
  }

  const _placeBuy = () => {
    placeBid({ bid_type: 2, bid_source: nature === 'bid_status' ? 1 : 2, lot_id: data.lot_id, lot_number: data.lot_number, lot_title: data.desc_title, visible_high_bid: data.price_highbid, resolved_bid: data.price_reserve })
  }

  const _unwatch = () => {
    unwatch({lot_id: data.lot_id, lot_number: data.lot_number})
  }

  const _acceptUnderReserve = () => {
    promptAcceptUnderReserve(data)
  }

  const _reduceReserve = () => {
    promptReduceReserve(data)
  }

  const _approveConsignorAdjustment = () => {
    props.approveAdjustment(data.id)
  }

  const _openLotActions = () => {
    props.promptAdvancedAdjustment(data.id)
  }

  const modeUser = nature === 'bid_status' || nature === 'watchlist'
  const modeAdmin = nature === 'resolution' || nature === 'lot_actions'
  const lot_adjustment_status = data.admin_reviewed_adjustment ? 2 : (data.cs_accept || data.cs_reduce_reserve ? 1 : 0)

  let closed_actual = false
  let closed_local = false
  let closing_text
  let closing_color

  // action elements
  const button_unwatch = <div className="lot-action-button" onClick={_unwatch}><i className="fa fa-close margin-right-3"></i><span className="lot-action-button-text">Unwatch</span></div>
  const button_qwikbid = <button className="btn btn-warning btn-xs mini-button" onClick={_placeQwikBid}><i className="fa fa-bolt"></i> QwikBid</button>
  const button_approval = <div className="lot-action-button button-orange" onClick={_approveConsignorAdjustment}><i className="fa fa-thumbs-up margin-right-3"></i><span className="lot-action-button-text">{data.cs_reduce_reserve ? 'Lower Reserve to ' + formatMoneyInteger(data.cs_reduce_reserve_price) : 'Approve Bid Acceptance'}</span></div>
  const button_lotactions = <div className="lot-action-button button-light-blue text-bold" onClick={_openLotActions}><span className="lot-action-button-text">Actions</span><i className="fa fa-ellipsis-h margin-left-5"></i></div>
  const content_bid = <FusedFields data={data} onUpdate={inputChange} options={{ lot_number: data.lot_number, bid_source: nature === 'bid_status' ? 1 : 2 }} classes="action-elements-container">
    <input name="max_bid" placeholder="Enter Bid..." data-type="unsigned-int" autoComplete="off" />
    <button className="btn-u btn-u-darkred standard-button input-button" onClick={_placeBid}><span>Bid<i className="fa fa-angle-double-right margin-left-5"></i></span></button>
  </FusedFields>
  const content_buy = <div className="action-elements-container">
    <button className="btn-u btn-u-orange standard-button full-width" onClick={_placeBuy}><span>Buy Now for {formatMoneyInteger(data.price_reserve)}</span></button>
  </div>

  // cofigure closing/timer info
  if (auction_mode === 2) {
    let time_remaining = 0
    let time_remaining_color = yellow
    let time_remaining_text = null
    closed_actual = data.status_closed === 1 || data.time_since_lastbid === null || data.time_since_lastbid >= bid_extension_time
    const literal_time_remaining = bid_extension_time - (data.time_since_lastbid + elapsedSeconds)
    time_remaining = literal_time_remaining > 0 ? literal_time_remaining : 0
    closed_local = closed_actual || (time_remaining === 0)
    time_remaining_color = time_remaining <= 60 ? red : yellow
    time_remaining_text = time_remaining <= 0 ? <img src="/static/img/loading_dots_blue.gif" alt=""/> : (view === 'mobile' ? "Closing in " : '') + toMMSS(time_remaining)
    closing_text = closed_actual ? (data.reserve_met ? (modeUser ? 'closed' : 'sold') : (modeUser || data.price_highbid ? 'unsold' : 'no bids')) : data.bid_info_stale ? <img src="/static/img/loading_dots_blue.gif" alt=""/> : time_remaining_text
    closing_color = closed_actual ? (closing_text === 'unsold' ? yellow : (((nature === 'bid_status' && data.is_high_bidder) || (!modeUser && data.reserve_met)) ? green : red )) : time_remaining_color
  } else if (auction_mode > 2) {
    closed_actual = true
  }

  // configure status info
  let status_color, status_icon, status_text, status_won_icon
  const status_icon_check = <i className={"fa fa-check" + (view === "mobile" ? "-circle" : '')}></i>
  const status_icon_exclamation = <i className="fa fa-exclamation-circle"></i>
  const status_icon_minus = <i className="fa fa-minus-circle"></i>
  if (nature === 'bid_status') {
    const bid_winning = data.is_high_bidder && data.reserve_met
    status_color = bid_winning ? green : (data.is_high_bidder ? yellow : red)
    status_text = closed_actual && bid_winning ? 'Won' : (bid_winning ? 'Winning' : (data.is_high_bidder ? 'Under Reserve' : 'Outbid'))
    status_icon = status_color === bid_winning ? status_icon_check : status_icon_exclamation
    status_won_icon = closed_actual && bid_winning ? <i className="fa fa-check text-success" style={{ fontSize: '25px' }} aria-hidden="true"></i> : null
  } else {
    status_color = nature === 'watchlist' ? blue : (data.reserve_met ? green : (data.price_highbid ? yellow : red))
    status_text = closed_actual && data.reserve_met ? 'Sold' : (data.reserve_met ? 'Reserve Met' : (data.price_highbid ? 'Under Reserve' : 'No Bids'))
    status_icon = data.reserve_met ? status_icon_check : (data.price_highbid ? status_icon_exclamation : status_icon_minus)
  }

  //default config (may be overridden)
  const max_bid_display = finalizeValue(nature === 'bid_status' ? data.current_max_bid : data.price_maxbid)
  const high_bid_display = finalizeValue(data.price_highbid) + (!data.reserve_met ? '*' : '')
  const minimum_bid_display = closed_actual ? 'n/a' : formatMoneyInteger(next_higher_bid(data.price_highbid, data.price_minimumbid))

  let action1, action2, action3, action4, pricebar1, pricebar2

  switch (nature) {
    case "bid_status":
    {
      pricebar1 = config_pricebar({ leftLabel: "Your Max Bid:", leftValue: max_bid_display, centerValue: data.current_max_bid > data.price_highbid ? '>' : (data.current_max_bid < data.price_highbid ? '<' : '='), centerValueColor: status_color, rightLabel: "High Bid:", rightValue: high_bid_display, rightValueColor: status_color })
      action2 = !closed_actual ? content_bid : (!data.reserve_met && (auction_mode === 2 || auction_mode === 3 || auction_mode === 4) ? content_buy : null)
      break
    }
    case "watchlist":
      pricebar1 = config_pricebar({ leftLabel: "Min Bid:", leftValue: minimum_bid_display, rightLabel: "High Bid:", rightValue: high_bid_display })
      action1 = button_unwatch
      action2 = !closed_actual ? content_bid : (!data.reserve_met && (auction_mode === 2 || auction_mode === 3 || auction_mode === 4) ? content_buy : null)
      break
    case "consignor_status":
    {
      pricebar1 = config_pricebar({ leftLabel: "Reserve:", leftValue: finalizeValue(data.price_reserve), centerValue: data.price_reserve > data.price_highbid ? '>' : (data.price_reserve < data.price_highbid ? '<' : '='), centerValueColor: status_color, rightLabel: "High Bid:", rightValue: high_bid_display, rightValueColor: status_color })
      const content_reduce_reserve = !data.reserve_met ? <FusedFields data={data} onUpdate={inputChange} options={{ lot_id: data.id }} classes="action-elements-container">
        <input name="potential_reduced_reserve_price" placeholder="New Reserve..." data-type="unsigned-int" autoComplete="off" />
        <button className="btn-u btn-u-blue standard-button input-button" onClick={_reduceReserve}><span><i className="fa fa-arrow-right"></i></span></button>
      </FusedFields> : null
      const content_accept_under_reserve = !data.reserve_met && data.price_highbid && auction_mode === 3 ? <div className="lot-action-button button-grey" onClick={_acceptUnderReserve}><i className="fa fa-check-circle margin-right-3"></i><span className="lot-action-button-text">Accept Bid</span></div> : null
      const lot_adjustment_notification = <div className={"label rounded pull-right adjustment-status " + (lot_adjustment_status === 2 ? "adjustment-status-complete" : "adjustment-status-pending")}>{lot_adjustment_status === 2 ? <span><i className="fa fa-check-circle margin-right-5"></i>Adjustment Resolved</span> : <span><i className="fa fa-exclamation-triangle margin-right-5"></i>Adjustment Under Review</span> }</div>
      action1 = (auction_mode === 3 || auction_mode === 4) ? (lot_adjustment_status > 0 ? lot_adjustment_notification : content_accept_under_reserve) : null
      action2 = lot_adjustment_status === 0 && (auction_mode === 3 || auction_mode === 4) ? content_reduce_reserve : null
      break
    }
    case "resolution":
    {
      pricebar1 = config_pricebar({ leftLabel: "Max Bid:", leftValue: max_bid_display, centerLabel: "Bidder:", centerValue: <a href={"mailto:" + data.bidder_email}>{data.bidder_name}</a>, rightLabel: "High Bid:", rightValue: high_bid_display })
      pricebar2 = config_pricebar({ leftLabel: "Init:", leftValue: finalizeValue(data.price_initialreserve), centerLabel: "Consignor:", centerValue: data.consignor_name, rightLabel: "Reserve:", rightValue: finalizeValue(data.price_reserve) })
      const lot_adjustment_notification = lot_adjustment_status === 2 ? <div className={"notify-status"}><span className="status-resolved"><i className="fa fa-check-circle margin-right-5"></i>Resolved</span></div> : null
      action3 = (auction_mode === 3 || auction_mode === 4) ? (lot_adjustment_status === 1 ? button_approval : lot_adjustment_notification) : null
      action4 = button_lotactions
      break
    }
    case "lot_actions":
    {
      // same pricebar config as resolution?
        pricebar1 = config_pricebar({ leftLabel: "Min Bid:", leftValue: finalizeValue(data.price_minimumbid), centerLabel: "Bidder:", centerValue: <a href={"mailto:" + data.bidder_email}>{data.bidder_name}</a>, rightLabel: "High Bid:", rightValue: high_bid_display })
        pricebar2 = config_pricebar({ leftLabel: "Max Bid:", leftValue: max_bid_display, centerLabel: "Consignor:", centerValue: data.consignor_name, rightLabel: "Reserve:", rightValue: finalizeValue(data.price_reserve) })
      const lot_adjustment_notification = lot_adjustment_status === 2 ? <div className={"notify-status"}><span className="status-resolved"><i className="fa fa-check-circle margin-right-5"></i>Resolved</span></div> : null
      action3 = (auction_mode === 3 || auction_mode === 4) ? (lot_adjustment_status === 1 ? button_approval : lot_adjustment_notification) : null

      // ZZZ - still need to flesh out remaining data in tabular mode (desktop)
      break
    }
  }

  // render standard or mobile view
  if (view === 'standard') {
    return <tr className={"lot-element lot-tabular " + (data.promoting ? 'watchlist-promoted' : '') }>
      <td><Link to={'/catalog/lot/' + data.auction_id + '/' + data.lot_number}>{data.lot_number}</Link></td>
      {!modeUser && <td>{data.consignor_serial}</td>}
      {nature !== 'lot_actions' && <td className="constrain-text">{data.desc_title}</td>}
      {nature === 'watchlist' && <td>{!data.promoting && button_unwatch}</td>}
      <td className="constrain-text">{modeAdmin ? data.consignor_name : data.creator}</td>
      {nature === 'resolution' && <>
        <td className="constrain-text">{data.bidder_name}</td>
        <td className="constrain-text"><a href={"mailto:" + data.bidder_email}>{data.bidder_email}</a></td>
      </>}
      {nature === 'lot_actions' && <td><div className={classNames("currency-value", blue)}>{finalizeValue(data.price_minimumbid, 'invalid')}</div></td>}
      <td>
        <div key={data.price_highbid} className={classNames("currency-value", status_color, [`bid-updated-${status_color}`])}>{high_bid_display}</div>
      </td>
      {(nature === 'bid_status' || nature === 'lot_actions') && <td><div key={data.current_max_bid} className={classNames("currency-value", blue, [`bid-updated-${blue}`])}>{max_bid_display}</div></td>}
      {nature === 'watchlist' && auction_mode <= 2 && <td><div className="currency-value">{minimum_bid_display}</div></td>}
      {(nature === 'resolution' || nature === 'consignor_status') && <td><div className={classNames("currency-value", blue)}>{finalizeValue(data.price_initialreserve, 'invalid')}</div></td>}
      {!modeUser && <td><div className={classNames("currency-value", blue)}>{finalizeValue(data.price_reserve, 'invalid')}</div></td>}
      {auction_mode === 2 && <td><span className={classNames("text-bold", closing_color)}>{closing_text}</span></td>}

      {/* won? */}
      {nature === 'bid_status' && auction_mode !== 1 && <td>{status_won_icon}</td>}

      {/* quikbid */}
      {nature === 'watchlist' && data.promoting && <td colSpan={auction_mode <= 2 ? 3 : 1} className="text-bold text-right">Updating <img className="margin-left-5 margin-right-5" src="/static/img/loading_dots_blue.gif" alt=""/></td>}
      {modeUser && auction_mode <= 2 && !data.promoting && <td>{!closed_local && !data.is_high_bidder && button_qwikbid}</td>}
      {modeUser && auction_mode <= 2 && !data.promoting && <td>{!closed_local && !data.is_high_bidder && "-or-"}</td>}

      {/* place new bid/buy */}
      {modeUser && auction_mode <= 4 && !data.promoting && <td>{action2}</td>}

      {/* status */}
      {!modeUser && <td><div className={classNames("icon-element", "text-center", status_color)} >{status_icon}</div></td>}

      {nature === 'consignor_status' && (auction_mode === 3 || auction_mode === 4) && <>
        {/* adjustments1 - accept under reserve */}
        <td colSpan={lot_adjustment_status === 0 ? 1 : 2}>{action1}</td>
         {/* adjustments2 - lower reserve price */}
        {lot_adjustment_status === 0 && <td>{action2}</td>}
      </>}

      {nature === 'resolution' && <>
        {/* adjustments - admin approval */}
        {(auction_mode === 3 || auction_mode === 4) && <td>{action3}</td>}
        {/* show lot actions */}
        <td>{action4}</td>
      </>}
    </tr>
  } else {
    return <div className={"lot-element lot-card " + status_color + (data.promoting ? ' watchlist-promoted' : '')} style={style}>
      <div className="lot-card-header">
        <div className="left-block lot-card-title">
          <Link className={status_color} to={'/catalog/lot/' + data.auction_id + '/' + data.lot_number}>Lot {data.lot_number}{!modeUser && <span> ({data.consignor_serial})</span>}</Link>
        </div>
        <div className={"right-block lot-card-status " + status_color}>
          <span>{status_text}</span>{status_icon}
        </div>
      </div>
      <div className="description-block-title">{data.desc_title}</div>
      {!modeAdmin && <div className="description-block-creator">{data.creator}</div>}
      <div className={"lot-card-price-bar"}>
        <div className="left-block">
          <div className="price-block-label">
            {pricebar1.leftLabel}
          </div>
          <div className={"price-block-value " + pricebar1.leftValueColor}>
            {pricebar1.leftValue}
          </div>
        </div>
        <div className="center-block">
          {pricebar1.centerLabel ? <>
            <div className="price-block-label">
              {pricebar1.centerLabel}
            </div>
            <div className={"price-block-value " + pricebar1.centerValueColor}>
              {pricebar1.centerValue}
            </div>
          </> :
          <div className={"price-block-comparator " + pricebar1.centerValueColor}>
            {pricebar1.centerValue}
          </div>}
        </div>
        <div className="right-block">
          <div className="price-block-label">
            {pricebar1.rightLabel}
          </div>
          <div className={"price-block-value " + pricebar1.rightValueColor}>
            {pricebar1.rightValue}
          </div>
        </div>
      </div>
      {pricebar2 && <div className={"lot-card-price-bar"}>
        <div className="left-block">
          <div className="price-block-label">
            {pricebar2.leftLabel}
          </div>
          <div className={"price-block-value " + pricebar2.leftValueColor}>
            {pricebar2.leftValue}
          </div>
        </div>
        <div className="center-block">
          <div className="price-block-label">
            {pricebar2.centerLabel}
          </div>
          <div className={"price-block-value " + pricebar2.centerValueColor}>
            {pricebar2.centerValue}
          </div>
        </div>
        <div className="right-block">
          <div className="price-block-label">
            {pricebar2.rightLabel}
          </div>
          <div className={"price-block-value " + pricebar2.rightValueColor}>
            {pricebar2.rightValue}
          </div>
        </div>
      </div>}
      {closing_text && <div className={"lot-card-closing-bar " + closing_color}>{closing_text}</div>}
      {action1 && <div className={"lot-card-action-bar center-items"}>
        {action1}
      </div>}
      {action1 && action2 && <div className="center-block">or</div>}
      {action2 && <div className="lot-card-input-bar">{action2}</div>}
      {(action3 || action4) && <div className={"lot-card-action-bar"}>
        {action3 && <div className={"left-block"}>
          {action3}
        </div>}
        {action4 && <div className={"right-block"}>
          {action4}
        </div>}
      </div>}
    </div>
  }
}

export default LotCard
