import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import NotificationBox from '../../shared/NotificationBox'

class ComingSoon extends Component {

  render() {
    return (
      <div>
        <Helmet>
          <title>Coming Soon</title>
        </Helmet>
        <div className="dashboard-content">
          <div className="tag-box tag-box-v3 margin-bottom-40">
            <NotificationBox mode='info'>
              <span className="text-bold" style={{fontSize: '20px'}}>Coming soon...</span>
            </NotificationBox>
            <h4>We're working on our new site</h4>
            <h5>This content will be available soon.</h5>
          </div>
        </div>
      </div>
    )
  }
}

export default ComingSoon
