import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import useCountSinceDataLoaded from '../../shared/hooks/useCountSinceDataLoaded'

AuctionTimer.propTypes = {
  siteinfo: PropTypes.object.isRequired,
  siteinfo_meta: PropTypes.object.isRequired,
  getSiteInfo: PropTypes.func.isRequired,
}

AuctionTimer.defaultProps = {
}

function AuctionTimer({ siteinfo, siteinfo_meta, getSiteInfo }) {
  const elapsedSeconds = useCountSinceDataLoaded(siteinfo_meta)

  useEffect(() => {
    const localtime = Date.now()
    // console.log('localtime:', localtime)
    // console.log('siteinfo-localtime:', siteinfo.localtime)
    // console.log('siteinfo_meta.timestamp:', siteinfo_meta.timestamp)
    // ZZZ - rethink global siteinfo refresh in the overall site context - keeping as legacy code for now
    if (localtime - siteinfo.localtime > 10000) {
      getSiteInfo()
    }
  }, [siteinfo.localtime, getSiteInfo])

  const rawtime = siteinfo_meta.timestamp + (elapsedSeconds * 1000)
  const time_left = parseInt(siteinfo.auction_end_date - rawtime)
  const time_left_seconds = time_left / 1000

  const r_days = String(Math.floor(time_left_seconds / (3600 * 24)))
  const r_hours = String(Math.floor((time_left_seconds / 3600) % 24))
  const r_minutes = String(Math.floor((time_left_seconds / 60) % 60))
  const r_seconds = String(Math.floor(time_left_seconds % 60))

  let displayed_time
  if (r_days > 0) {
    displayed_time = <span>{r_days}d {r_hours}h</span>
  } else if (r_hours > 0) {
    displayed_time = <span>{r_hours}h {r_minutes}m</span>
  } else {
    displayed_time = <span>{r_minutes}m {r_seconds}s</span>
  }

  return time_left > 0 ? <div>Auction closes in {displayed_time}</div> : <div>Auction is in the <Link to="resources/faq#auction-closing">10 minute rule</Link> – bid now!</div>
}

export default AuctionTimer