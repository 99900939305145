import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>Guide to the Auction</title>
        <link rel="canonical" href={props.location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>Guide to the Auction</h2>
      </div>

      <div className="headline">
        <h4>CATALOG</h4>
      </div>
      <p>Each item is described as completely as is practical. Please feel free to request additional information on any item. We will be pleased to describe the item more fully and give a detailed condition report. However, we ask that you not request additional information on the last day of the auction, as we are fully committed to taking telephone bids during that time.</p>
      <p>The catalog is organized primarily by geographical region. You can browse the current auction catalog using the geographical and subject categories on the left side of the page.  You can also use our basic or advanced search options near the top of the page to find items matching your specific criteria.</p>
      <p>Each lot description follows a basic format, and includes:</p>
      <div className="shadow-wrapper">
        <div className="box-shadow shadow-effect-1">
          <table className="table table-striped">
            <colgroup>
              <col style={{width: '180px'}} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td>Title</td>
                <td>exactly as it appears, in the language of the publication. Titles in [square brackets] indicate the map is untitled or the title appears on the verso.</td>
              </tr>
              <tr>
                <td>Maker</td>
                <td>cartographer, artist, engraver, author, or publisher.</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>dated, published, or circa<br />Note: dated maps are not necessarily published on this date as it was common practice to publish maps for a period of time without changing the date. If we have information about the actual publication date it will be noted in the description.</td>
              </tr>
              <tr>
                <td>Source Publication</td>
                <td>if known</td>
              </tr>
              <tr>
                <td>Condition Code</td>
                <td>A+ through D (see table below)</td>
              </tr>
              <tr>
                <td>Color</td>
                <td>uncolored, hand-colored, or printed color. If we are certain that the coloring is original we will note that in the description. The age of coloring is often difficult to determine with certainty. Please contact us directly if you would like more information on the color of a map.</td>
              </tr>
              <tr>
                <td>Size</td>
                <td>width before height. This includes only the printed image (within 1/4"), as measured from neatline to neatline.</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>including the salient features, historic significance, and references. Also included is a description of any significant flaws, damage or repairs.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="headline">
        <h4>CONDITION</h4>
      </div>
      <p>Every effort is made to accurately describe the condition of each item. While condition is important, it must also be kept in mind that all items are original, generally several hundred years old, and should not be expected to be as new. Insignificant flaws, such as tiny spots or printer's creases have only a minimal effect on the value of an item and are not considered imperfections. Single centerfolds that were done at the time of publication are so common on maps that they are not described in the condition. Multiple folds and binding trims that were done at the time of publication are also not considered defects but are typically described.</p>
      <p>We have used the following guidelines to develop the condition code system. These condition descriptions are meant to provide a general guide to what can be expected. Significant flaws are noted in addition to the code. Please contact us if you need further information. We will be happy to provide a detailed condition report or to discuss the condition with you personally.</p>
      <div className="shadow-wrapper">
        <div className="box-shadow shadow-effect-1">
          <table className="table table-striped">
            <colgroup>
              <col style={{width: '70px'}} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td>A+</td>
                <td>Fine condition with no noticeable imperfections in the image or blank margins. Any minor restorations or repairs are confined to the margins and have been accomplished professionally.</td>
              </tr>
              <tr>
                <td>A</td>
                <td>Near fine condition with no noticeable imperfections in the image, and only minor imperfections in the blank margins such as edge tears, short fold separations or small stains. Any minor restorations in image or margins have been accomplished with archival materials.</td>
              </tr>
              <tr>
                <td>B+</td>
                <td>Very good condition with unobtrusive imperfections, such as light toning, offsetting, soil, damp stains or tiny spots in image and margins. May have unrepaired fold separations or tears that are confined to the blank margins. Any minor restorations in image or margins have been accomplished with archival materials.</td>
              </tr>
              <tr>
                <td>B</td>
                <td>Good condition with no significant imperfections. The paper is sound or any paper weakness has been reinforced with archival materials. There may be some wormholes, stains, foxing, uneven toning, repaired tears or short fold separations in the image. Any restorations or repairs in the image have been accomplished with archival materials.</td>
              </tr>
              <tr>
                <td>C+</td>
                <td>Satisfactory condition with noticeable imperfections. The paper may have some minor weakness. May have trimmed margins, weak or uneven impression, wormholes, spotting, foxing or uneven toning, repaired tears in the image, or fold separations with slight image loss. Any restorations or repairs in the image have been accomplished with archival or removable materials.</td>
              </tr>
              <tr>
                <td>C</td>
                <td>Fair condition with noticeable imperfections in need of repair. May have trimmed margins with minor loss of border, or tears or long fold separations that can be easily repaired. May have repairs that have been accomplished with unknown materials.</td>
              </tr>
              <tr>
                <td>D</td>
                <td>Poor condition with defects in need of significant repair or cleaning. Paper may be highly acid and brittle, severely toned or soiled. May have missing image or significant flaws. May have inappropriate repairs that will require the attention of a professional.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="headline">
        <h4>USING THE ONLINE CATALOG, BIDDING QUESTIONS...</h4>
      </div>
      <p>An estimate is provided as a guide to the item's value. These estimates are conservative and based, wherever possible, on past auction prices and current market prices. Lots may end up selling for more or less than the estimated price. You may bid any amount, but you must bid according to the bidding increments. Bids received, which are not in accordance with these increments, will be reduced to the next lower increment. See the bidding increments table listed on any lot page.</p>

      <div className="headline">
        <h4>RESERVES</h4>
      </div>
      <p>Most lots in the auction will have a reserve, which will be between 50% and 75% of the low estimate. Reserves are confidential between OWA and the consignor. Any lots that do not have a reserve will be marked as a "No Reserve" lot.  If you submit a bid below the reserve it will be accepted at the amount submitted and you will be notified that the seller is not obligated to accept the bid.</p>

      <div className="headline">
        <h4>PROXY BIDDING</h4>
      </div>
      <p>The proxy bidding system is designed to simulate what happens in a floor auction. The computer system will act as your bidding agent by entering your bid competitively against other bidders. If there are multiple bidders on a lot, the winning bid is always reduced to one increment above the highest competing bid.  If there are no other bidders on a lot, the winning bid is always reduced to the reserve amount. For example, if the highest bid for a lot is $100 and the highest competing bid by another bidder is $60, the winning bid will be $65. If an item has a reserve, and no other bids exist, the bid will be reduced to the reserve.</p>
      <p>For example, if there is a confidential reserve of $150 on a lot, and Person A has placed a high bid of $100, then a proxy bid of $200 by Person B will increase the current high bid to $150 due to the reserve. If then Person A places a proxy bid of $250, the current high bid will increase to $210, since that is one increment above Person B's proxy bid. Bids below the reserve are not reduced. Your maximum bid is always completely confidential.</p>

      <div className="headline">
        <h4>BIDDING STATUS</h4>
      </div>
      <p>Immediately after placing a bid, a pop-up box will advise you of the status of that bid. Either you will be the current high bidder or not. Please read this screen carefully to determine the status of your bid. The current high bid will always be shown. Please note that if your bid ties with a previous bidder's maximum bid, then the previous bidder will have the high bid because they entered the bid amount first, even though the high bid amount shown matches your bid.</p>
      <p>After placing bids, you may view the status of all your bids on your <Link to={'/user/status'}>Bid Status page</Link>. Items listed in green are those for which you are the high bidder and the bid amount has met the reserve. Items listed in yellow are those for which you are the high bidder, but the bid amount is below the reserve. Items listed in red are those for which you have been outbid by another bidder. If you have been outbid on a lot, you will receive an Outbid Notice email, and you may place another bid either on the Bid Status page or the lot detail page.</p>

      <div className="headline">
        <h4>AUCTION CLOSING</h4><h5>("10-Minute Rule" applies to individual lots)</h5>
      </div>
      <p>The auction begins closing at 10:00 PM Eastern Time. During this time the message "Auction Closing" displays at the top of each page. At 10 pm, all lots without a bid within the last 10 minutes will close immediately. A lot that has had a bid in the past 10 minutes will remain open until a bid has not been received for 10 minutes. This simulates what may happen in a floor auction in which the auctioneer signals the impending close for a lot, but does not bring the final hammer down as long as there is bidding from the floor. The 10-Minute Rule eliminates the problem of sniping often associated with internet auctions. You may check your status at any time on your <Link to={'/user/status'}>Bid Status page</Link> and observe when each item closes in real time.</p>
      <p>We strongly suggest that you simply enter your maximum bid early and let our Proxy system competitively enter your bids. This way you are relieved of closely monitoring the auction with the chance of losing the item. We get heavy traffic on the website and on the telephone at the close of the auction. All bids via phone or email must be received 15 minutes prior to the close (10:00 PM) to give us time to enter them prior to the close. Remember that most lots close instantly at 10:00 PM, but those remaining open may take up to one hour or more to close, depending on the activity.</p>

      <div className="headline">
        <h4>BUYER&apos;S PREMIUM</h4>
      </div>
      <p>A 20% buyer&apos;s premium will be added to each lot with a hammer price below $5,000.  For lots with hammer prices equal to and above $5,000, a 15% buyer&apos;s premium will be added.</p>

      <div className="headline">
        <h4>INVOICING</h4>
      </div>
      <p>Invoices will be prepared within five business days following the auction close and you will receive an email when your invoice is complete. Please ensure that your <Link to={'/user/profile'}>Account Info</Link> has your current email address and shipping address. Payment is due on receipt of the invoice. You must approve the invoice or contact us directly within three days of the receipt of the invoice. Invoices that are not acknowledged within three days may be cancelled and the sale rescinded, at the option of Old World Auctions. Invoices not paid within 30 days will incur a $25.00 additional charge, and may be rescinded, at the option of Old World Auctions. The items are shipped as soon as possible after receipt of your payment. We appreciate your prompt payment as this allows us to pay our consignors equally promptly.</p>

      <div className="headline">
        <h4>SHIPPING</h4>
      </div>
      <p>Packaging, shipping and insurance is extra and will be added to the invoice. Unless specific instructions are received, Old World Auctions will determine the best method of packaging and shipment. We generally ship via USPS, FedEx, UPS or DHL depending on the value and destination of the package. You will be charged the actual costs for packaging, insurance and shipping. You will have the opportunity to review the shipping details for your purchases directly on your invoice, including the carrier, insurance, and delivery signature. If you want to change any of these options, you can do so directly on your invoice and the OWA staff will make any necessary changes to your shipping cost. Once the items have been shipped you will receive an email from the shipping carrier with your tracking information and we will post the ship date on your <Link to={'/user/invoices'}>invoice</Link>.</p>
      <p>Open and inspect your package immediately upon receipt. Any discrepancies, damage or missing items must be reported to us within 2 business days of receipt by you. All packaging materials must be retained until the discrepancy has been resolved.</p>

      <div className="headline">
        <h4>GUARANTEE</h4>
      </div>
      <p>We warranty the authenticity and condition of each lot described in this catalog with a 100% money-back guarantee. While we make every effort to carefully describe defects, it is not possible to note every minor problem in the catalog. If you have concerns about the condition of an item, please contact us for a complete condition report prior to placing your bid. If any lot differs significantly from the catalog description it may be returned within one week of receipt. <b>However, this should not be interpreted as a sale on approval.</b> Returns will only be authorized when there are significant defects that were not described that affect the value of the item. Please call for a Return Authorization prior to returning. No returns will be accepted for any reason after 30 days following the auction close date.</p>

      <div className="headline">
        <h4>COPYRIGHT</h4>
      </div>
      <p>Old World Auctions is the exclusive owner of all intellectual property rights, including copyright, and other rights, in the catalog, in software used in or on this site, and in the compilation of all visual information, text and illustrations contained in this site. Copyright laws and international conventions protect these rights. It is unlawful for anyone to copy, reproduce or transmit such software, information, text or illustrations or to use Old World Auctions' trademarks, without our specific written consent. You are granted a limited license to make personal use of this site solely to bid on or purchase items being offered at auction and for other purposes strictly related to your participation in the auction, and for no other purpose.</p>

    </div>
  )
}

export default content
