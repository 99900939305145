import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLots } from 'rdx/modules/lots'
import { getUserData, toggleWatch } from 'rdx/modules/auction'

import DataList from '../shared/DataList'
import LotSummary from './LotSummary'
import { SiteView } from '../base/components/siteview-context'
import PrintButton from '../shared/PrintButton'

// import useCountSinceDataLoaded from '../shared/hooks/useCountSinceDataLoaded'

// if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
//   history.scrollRestoration = 'manual'
// }

CatalogList.propTypes = {
  lots: PropTypes.object.isRequired,
  auction: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  catalog_mode: PropTypes.string.isRequired,
  getLots: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
  toggleWatch: PropTypes.func.isRequired,
}

CatalogList.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getLots(location, params)),
    dispatch(getUserData()),
  ])
}

function CatalogList({ lots, auction, credentials, location, params, siteinfo, catalog_mode, getLots, getUserData, toggleWatch }) {
  useEffect(() => {
    getLots(location, params)
    getUserData()
  }, [location, params, getLots, getUserData])

  // const elapsedSeconds = useCountSinceDataLoaded(lots.content.meta)

  if (!lots.content.info.count === 0) {
    return <div>Loading...</div>
  }

  let content = <DataList
    itemDisplayName='lot'
    errorCondition={lots.content.meta.status > 1}
    errorMessage={lots.content.meta.statusText}
    isLoading={lots.content.meta.processing}
    current_auction={siteinfo.current_auction}
    auction_mode={siteinfo.auction_mode}
    data={lots.content.data}
    totalItemCount={lots.content.info.count}
    datakey='id'
    auxDataKey='lot_number'
    auxData1={auction.bids.data}
    auxData2={auction.watchlist.data}
    pageSize={lots.content.info.pageSize}
    route_pathname={location.pathname}
    route_query={location.query}
    customProp={'test1'}
    toggleWatch={toggleWatch}
    params={params}
    location={location}
  >
    <LotSummary
      isAuthenticated={credentials.isAuthenticated}
      userDataInit={auction.bids.meta.status === 1 && auction.watchlist.meta.status === 1}
      // elapsedSeconds={elapsedSeconds}
      catalog_mode={catalog_mode}
      siteinfo={siteinfo}
    />
  </DataList>

  return <SiteView.Consumer>
    {mode => (
      <div className="dashboard-content">
        {!mode.wrap_content && <PrintButton />}
        {content}
      </div>
    )}
  </SiteView.Consumer>
}

function mapStateToProps(state) {
  return {
    lots: state.lots,
    auction: state.auction,
    credentials: state.auth.login.data,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getLots, getUserData, toggleWatch }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogList)