import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from 'react-router'
import Collapsible from 'react-collapsible'


import { connect } from 'react-redux'
import { processRegistration } from 'rdx/modules/auth/registration'
import { logout } from 'rdx/modules/auth/login'

import HeaderLanding from '../base/components/HeaderLanding'
import FusedFields from '../shared/fused-fields'
import FormRow from '../shared/FormRow'
import FieldBase from '../shared/FieldBase'
import FieldText from '../shared/FieldText'
import FieldCheckbox from '../shared/FieldCheckbox'
import NotificationBox from '../shared/NotificationBox'
import PasswordValidator from '../shared/PasswordValidator'

import { makeOptions, makeOptionsBoolean } from 'lib/utility'
import { validatePassword, validateEmail } from 'lib/validation'
import { user_options, invoice_options } from 'lib/constants'

const fields_initialstate = {
  user_email_pref_auction_reminders: true,
  user_email_pref_outbid_notices: true,
  user_email_pref_price_reductions: true,
  user_email_pref_newsletter: true,
}

Register.propTypes = {
  location: PropTypes.object.isRequired,
  meta: PropTypes.object,
  validation: PropTypes.object,
  processRegistration: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,

  isAuthenticated: PropTypes.bool,
}

function Register({ location, meta, validation, isAuthenticated, processRegistration, logout }) {
  const [fields, setFields] = useState(fields_initialstate)
  const [showShipping, setShowShipping] = useState(false)
  const recaptchaRef = useRef()

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _processRegistration = () => {
    processRegistration(fields, recaptchaRef.current)
  }

  const _toggleShipping = () => setShowShipping(prevShowShipping => !prevShowShipping)

  const modeLanding = typeof location.query.referral !== 'undefined'
  const required_notification = <span className="input-group" style={{ display: 'flex', alignItems: 'center', height: '34px', marginTop: '25px' }}><i className="fa fa-asterisk fa-2x text-danger" style={{ color: 'red', marginRight: '10px' }}></i>Required info</span>
  let email_alert = null
  let pwd_alert = null

  if (typeof fields.user_email_verify !== 'undefined' && fields.user_email_verify !== '') {
    console.log('user_email_verify:', fields.user_email_verify)
    const email_different = fields.user_email !== fields.user_email_verify
    let message = email_different ? 'Email addresses do not match' : (!validateEmail(fields.user_email) ? "Email address may not be valid--please review" : null)
    email_alert = email_different ? <span className="input-group" style={{ display: 'flex', alignItems: 'center', height: '34px', marginTop: '25px' }}><i className="fa fa-exclamation-triangle fa-2x text-danger" style={{ marginRight: '10px' }}></i>{message}</span> : (!validateEmail(fields.user_email) ? <span className="input-group" style={{ display: 'flex', alignItems: 'center', height: '34px', marginTop: '25px' }}><i className="fa fa-fw fa-exclamation-triangle fa-2x text-warning" style={{ marginRight: '10px' }}></i>Email address may not be valid, please review</span> : null)
  }

  if (typeof fields.user_password_verify !== 'undefined' && fields.user_password_verify !== '') {
    console.log('user_password_verify:', fields.user_password_verify)
    const pwd_different = fields.user_password !== fields.user_password_verify
    let message = pwd_different ? 'Passwords do not match' : (!validatePassword(fields.user_password) ? "Password doesn't meet requirements" : null)
    pwd_alert = message ? <span className="input-group" style={{ display: 'flex', alignItems: 'center', height: '34px', marginTop: '25px' }}><i className="fa fa-fw fa-exclamation-triangle fa-2x text-danger" style={{ marginRight: '10px' }}></i>{message}</span> : null
  }

  const logged_in = <div>
    <div className="reg-header">
      <h2>{"You're already logged in!"}</h2>
    </div>
    <div className="row">
      <div className="col-md-12 text-center">
        <button className="btn-u" type="submit" onClick={logout}>Logout</button>
      </div>
    </div>
  </div>

  const processingNotification = <FormRow>
    <div className="col-lg-12">
      <NotificationBox mode='info'>
        <span>Processing registration<img className="pull-right margin-left-10" src="/static/img/loading_dots.gif" alt=""/></span>
      </NotificationBox>
    </div>
  </FormRow>

  const formErrorsNotification = <FormRow>
    <div className="col-lg-12">
      <NotificationBox mode='danger'>
        {meta.statusText ? <div>{meta.statusText}</div> : null}
        {validation.required ? <div>Some required data is missing</div> : null}
        {validation.other && validation.other.length ?
          validation.other.map((message, i) => {
            return <div key={i}>{message}</div>
          })
          : null
        }
      </NotificationBox>
    </div>
  </FormRow>

  const form_register = <div>
    <form action="#" className="form-general clearfix" style={{ height: '100%' }}>
      <FusedFields data={fields} errors={validation.required} onUpdate={_handleUpdate}>
        <FormRow>
          <FieldText name="user_firstname" required label="Name" groupClassName="col-md-6" placeholder="First" />
          <FieldText name="user_lastname" label="" groupClassName="col-md-6" placeholder="Last" />
        </FormRow>
        <FormRow>
          <FieldText name="user_email" required label="Email Address (this will also be your username)" groupClassName="col-md-6" />
          <div className="form-group col-md-6">
            {required_notification}
          </div>
        </FormRow>
        <FormRow>
          <FieldText name="user_email_verify" required label="Confirm your email address" groupClassName="col-md-6" />
          <div className="form-group col-md-6">
            {email_alert && email_alert}
          </div>
        </FormRow>
        <FormRow>
          <div className="col-md-6">
            <div className="row">
              <FieldBase type="password" name="user_password" autoComplete="new-password" required label="Create a password" groupClassName="col-md-12" />
            </div>
            <div className="row">
              <FieldBase type="password" name="user_password_verify" autoComplete="new-password" required label="Confirm your password" groupClassName="col-md-12" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <PasswordValidator value={fields.user_password} confirmValue={fields.user_password_verify} />
            </div>
          </div>
        </FormRow>
        <div style={{ fontSize: '18px' }}>
          Primary Contact Info
        </div>
        <div style={{ border: '1px solid #CCC', backgroundColor: '#f4f9ff', padding: '10px 10px 0 10px', marginBottom: '10px' }}>
          <FormRow>
            <FieldText name="primary_phone" required label="Phone Number" groupClassName="col-md-6" />
            <FieldText name="primary_company" label="Company" groupClassName="col-md-6" maxLength={ 50 } />
          </FormRow>
          <FormRow>
            <FieldText name="primary_address_line1" required label="Address" groupClassName="col-md-6" placeholder="Street and number, PO Box" />
            <FieldText name="primary_address_line2" label="" groupClassName="col-md-6" placeholder="Apartment, suite, unit, floor, etc" />
          </FormRow>
          <FormRow>
            <FieldText name="primary_city" required label="City" groupClassName="col-md-4" />
            <FieldText name="primary_state" required label="State/Province" groupClassName="col-md-4" />
            <FieldText name="primary_postalcode" required label="Zip/Postal Code" groupClassName="col-md-4" />
          </FormRow>
          <FormRow>
            <FieldBase type='countryDropdown' name='primary_country' required label='Country' groupClassName='col-md-12' />
          </FormRow>
        </div>
        <div className="margin-top-20 margin-bottom-20" style={{ display: showShipping ? 'none' : 'block' }}>
          <button type='button' className='btn btn-u' onClick={_toggleShipping}>
            {'Show Shipping Info (if different than primary info) '}
            <span className='fa fa-fw fa-arrow-right' />
          </button>
        </div>
        <Collapsible open={showShipping} classParentString="Collapsible-Bare">
          <div style={{ fontSize: '18px' }}>
            Shipping Info <span style={{ fontSize: '14px' }}>(if different than primary contact info)</span>
          </div>
          <div style={{ border: '1px solid #CCC', backgroundColor: '#fffdf4', padding: '10px 10px 0 10px', marginBottom: '10px' }}>
            <FormRow>
              <FieldText name="ship_phone" label="Phone Number" groupClassName="col-md-6" />
              <FieldText name="ship_company" label="Company" groupClassName="col-md-6" maxLength={ 50 } />
            </FormRow>
            <FormRow>
              <FieldText name="ship_address_line1" label="Address" groupClassName="col-md-6" placeholder="Street and number, PO Box" />
              <FieldText name="ship_address_line2" label="" groupClassName="col-md-6" placeholder="Apartment, suite, unit, floor, etc" />
            </FormRow>
            <FormRow>
              <FieldText name="ship_city" label="City" groupClassName="col-md-4" />
              <FieldText name="ship_state" label="State/Province" groupClassName="col-md-4" />
              <FieldText name="ship_postalcode" label="Zip/Postal Code" groupClassName="col-md-4" />
            </FormRow>
            <FormRow>
              <FieldBase type='countryDropdown' name='ship_country' label='Country' groupClassName='col-md-12' />
            </FormRow>
            <FormRow>
              <FieldBase type="select" name='ship_carrier_preference' label="Carrier Preference" groupClassName='col-lg-12'>
                {makeOptions(fields.ship_country === 'US' ? invoice_options.options_carrier_us : invoice_options.options_carrier_other, { filterHidden: true })}
              </FieldBase>
            </FormRow>
            {fields.ship_country === 'US' ?
              <FormRow>
                <FieldBase type="select" name='options_delivery_signature' label='Signature Required' groupClassName='col-lg-12'>
                  {makeOptionsBoolean()}
                </FieldBase>
              </FormRow>
            : null}
            <FormRow>
              <FieldBase type="textarea" name="ship_notes_user" label="Special Shipping Instructions" style={{ height: '50px' }} groupClassName="col-lg-12" maxLength={ 400 } />
            </FormRow>
          </div>
        </Collapsible>
        <div style={{ fontSize: '18px', borderBottom: '1px solid #CCC', marginBottom: '5px' }}>
          What kinds of email do you want to receive?
        </div>
        <FormRow>
          <FieldCheckbox name='user_email_pref_auction_reminders' groupClassName='col-lg-12' checked={fields.user_email_pref_auction_reminders == 1} label='Auction Reminders (4x / auction)' />
        </FormRow>
        <FormRow>
          <FieldCheckbox name='user_email_pref_outbid_notices' groupClassName='col-lg-12' checked={fields.user_email_pref_outbid_notices == 1} label='Outbid Notices (real time)' />
        </FormRow>
        <FormRow>
          <FieldCheckbox name='user_email_pref_price_reductions' groupClassName='col-lg-12' checked={fields.user_email_pref_price_reductions == 1} label='After-Auction Price Reductions (1x / auction)' />
        </FormRow>
        <FormRow>
          <FieldCheckbox name='user_email_pref_newsletter' groupClassName='col-lg-12' checked={fields.user_email_pref_newsletter == 1} label='Newsletters (8x / year) - info on antique maps, collecting tips, and events in the map community' />
        </FormRow>
        <div style={{ fontSize: '18px', borderBottom: '1px solid #CCC', marginBottom: '5px' }}>
          Additional Info
        </div>
        <FormRow>
          <FieldBase type="select" name='user_referralsource' required label='How did you learn about us?' groupClassName='col-md-6'>
            {makeOptions(user_options.user_referral_sources, { filterHidden: true })}
          </FieldBase>
        </FormRow>
        <FormRow>
          <FieldBase type="textarea" name="user_comments" label="Comments/Interests" style={{ height: '50px' }} groupClassName="col-lg-12" maxLength={ 700 } />
        </FormRow>
        <FormRow>
          <FieldCheckbox name='accept_terms' required groupClassName='col-md-6' checked={fields.accept_terms == 1} label={<span>Accept <a href='/resources/terms-and-conditions' target='_new'>Terms and Conditions</a></span>} labelStyle={{ fontSize: '16px' }} />
        </FormRow>
        {meta.processing ? processingNotification : null}
        {meta.status > 1 ? formErrorsNotification : null}
        <div style={{ zIndex: '1000' }}>
          <ReCAPTCHA
            size="invisible"
            ref={recaptchaRef}
            sitekey={__RECAPTCHA_KEY__}
            onExpired={() => {
              console.log('ReCAPTCHA-expired')
            }}
            onErrored={() => {
              console.log('ReCAPTCHA-errored')
            }}
          />
        </div>
        <FormRow>
          <div className="form-group col-md-12">
            <button type='button' className='pull-right btn btn-u' onClick={_processRegistration} disabled={fields.accept_terms !== 1}>
              <span className='fa fa-check' />
              {' Create Account'}
            </button>
          </div>
        </FormRow>
      </FusedFields>
    </form>
  </div>

  const successBox = <div className="col-lg-12 form-general" style={{minHeight: '450px'}}>
    <NotificationBox mode='success'>
      <span style={{ fontSize: '20px' }}>Your account is almost ready!</span>
    </NotificationBox>
    <div className="text-center" style={{ fontSize: '16px' }}>
      <h1 style={{ textAlign: 'center' }}>
        <i className="fa fa-fw fa-envelope"></i>
        <i className="fa fa-fw fa-long-arrow-right"></i>
        <i className="fa fa-fw fa-check" style={{ color:'green' }}></i>
      </h1>
      <p>Please look for an email from us<br />with a link to confirm your account information.<br /></p>
      <p>This needs to be visited within 24 hours of creating<br />the account or the registration will expire.</p>
      <p>If you have any difficulties, please contact us.</p>
      <br />
      <p>Until then, <Link to="/">click here to visit our home page</Link></p>
    </div>
  </div>

  const content = modeLanding ? <React.Fragment>
    <HeaderLanding />
    <div className="container content">
      {isAuthenticated ? logged_in :
        <div className="row">
          {meta.status !== 1 && <div className="col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 text-center margin-top-20 margin-bottom-20">
            <h2>Looking for antique maps? Trust the experts.</h2>
            <div className="text-left" style={{fontSize: '15px'}}>
              <div className="headline" style={{marginBottom: '5px'}}>
                <h4>Get Started</h4>
              </div>
              <p>Create an account below -- no credit card required. We never share, sell or trade your personal information.</p>
              <div className="headline" style={{marginBottom: '5px'}}>
                <h4>Who We Are</h4>
              </div>
              <p>Since 1978 Old World Auctions has been a leading online provider of collectible maps and atlases dating from the 14th to 20th centuries. We offer 5 online auctions each year with a variety of items from all over the world.</p>
              <div className="headline" style={{marginBottom: '5px'}}>
                <h4>Why You Can Bid with Confidence:</h4>
              </div>
              <ul style={{paddingLeft: '20px'}}>
                <li>Each item features a complete description of its age, historical significance, condition, and value range</li>
                <li>Certificates of authenticity on every item</li>
                <li>100% money-back guarantee</li>
                <li>A+ rating with the Better Business Bureau</li>
              </ul>
            </div>
          </div>}
          <div className="col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 margin-top-20 margin-bottom-80">
            {meta.status === 1 ? successBox : form_register}
          </div>
        </div>
      }
    </div>
  </React.Fragment> :
  <div className="container content">
    {isAuthenticated ? logged_in :
      <div className="row">
        {meta.status !== 1 && <div className="col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 text-center margin-top-20 margin-bottom-20">
          <div>
            <h1 style={{ textAlign: 'center' }}>Create Your Account</h1>
            <h4 style={{ textAlign: 'center' }}>We never have and never will share, sell or trade<br />your personal information with any outside parties</h4>
          </div>
        </div>}
        <div className="col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 margin-top-20 margin-bottom-40">
          {meta.status === 1 ? successBox : form_register}
        </div>
      </div>
    }
  </div>

  return <React.Fragment>
    <Helmet>
      <title>Register</title>
      <link rel="canonical" href={location.pathname} />
    </Helmet>
    {content}
  </React.Fragment>
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.login.data.isAuthenticated,
    meta: state.auth.registration.actions.register.meta,
    validation: state.auth.registration.actions.register.validation,
    fields: state.auth.registration.fields,
    err: state.auth.registration.err,
  };
}

export default connect(mapStateToProps, { processRegistration, logout })(Register)
