import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getData, setFieldValue, updateAuction } from 'rdx/modules/admin/auction'

import FieldText from '../shared/FieldText'
import FormRow from '../shared/FormRow'
import FieldBase from '../shared/FieldBase'
import SimpleContainer from '../shared/SimpleContainer'
import FusedFields from '../shared/fused-fields'

import ContentPanel from '../shared/ContentPanel'
import StatusBox from '../shared/StatusBox'
import { formatQueryParams, makeOptions } from 'lib/utility'
import { resolveAuctionDates } from 'lib/helper_auction';

AuctionDetail.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  auction: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  updateAuction: PropTypes.func.isRequired,
}

AuctionDetail.readyOnActions = (dispatch, params, location) => {
  const auction_id = params && params.auction_id ? parseInt(params.auction_id) : 0
  return Promise.all([
    dispatch(getData(auction_id))
  ])
}

function AuctionDetail({ location, params, auction, router, getData, setFieldValue, updateAuction }) {
  useEffect(() => {
    const auction_id = params && params.auction_id ? parseInt(params.auction_id) : 0
    getData(auction_id)
  }, [location, params, getData])

  const _backToList = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/admin/auctions', query: new_query })
  }

  const _prev = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/admin/auction/' + auction.content.prev, query: new_query })
  }

  const _next = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/admin/auction/' + auction.content.next, query: new_query })
  }

  const data = auction.content.data
  const { text_date_under_reserve_offer_process, text_date_post_sale_end } = resolveAuctionDates({ datetime_ending: data.datetime_ending, date_post_ending: data.date_post_ending })

  const title = 'Auction ' + (auction.meta.status === 1 ? data.id : params.auction_id)
  if (auction.meta.status !== 1) {
    return <StatusBox data={auction.meta} />
  }

  const content = <FormRow style={{ marginBottom: '5px' }}>
    <div className='col-lg-12 margin-bottom-10'>
      <FusedFields data={data} errors={auction.content.validation.required} onUpdate={setFieldValue} onLeave={setFieldValue}>
        <FormRow>
          <FieldBase type='select' name='auction_type' addonBefore='Type' groupClassName='col-lg-4' data-type='int'>
            {makeOptions([{ value: 0, text: 'Standard Auction' }, { value: 1, text: 'Close-by-Lot' }], { showVal: true })}
          </FieldBase>
        </FormRow>
        <FormRow>
          <FieldBase type="datetime-local" name="datetime_starting" addonBefore="Starting" groupClassName="col-lg-4" />
          <FieldBase type="datetime-local" name="datetime_ending" addonBefore="Ending" groupClassName="col-lg-4" />
          <FieldBase type="date" name="date_post_ending" addonBefore="Post-Auction End" groupClassName="col-lg-4" />
        </FormRow>
        <FormRow>
          <FieldText name='buyer_premium_base' addonBefore='Buyer Premium Base' groupClassName='col-lg-4' data-type="float" />
          <FieldText name='buyer_premium_tierA' addonBefore='TierA' groupClassName='col-lg-4' data-type="float" />
          <FieldText name='buyer_premium_tierA_threshold' addonBefore='TierA Threshold' groupClassName='col-lg-4' data-type="int" />
        </FormRow>
      </FusedFields>

      <div className="flex-generic-outer-container">
        <div className="flex-generic-container child-flex-grow child-gutter-10">
          <div className="tag-box tag-box-v3">
            <div className="section-summary" style={{ fontSize: '18px' }}>
              Resolved and auto-calculated info
            </div>
            <div><span className='fa fa-cubes fa-fw' /> Lot Count: <span className="text-bold color-light-blue">{data.lot_count}</span></div>
            <div><span className='fa fa-star-o fa-fw' /> Archived: <span className="text-bold color-light-blue">{data.archived ? 'True' : 'False'}</span></div>
            <div><span className='fa fa-clock-o fa-fw' /> Last Modified: <span className="text-bold color-light-blue">{data.meta_date_modified}</span></div>
            <div><span className='fa fa-calendar fa-fw' /> Auction End Date/Time: <span className="text-bold color-light-blue">{data.datetime_ending}</span></div>
            <div><span className='fa fa-calendar fa-fw' /> Under Reserve Offer Process Date: <span className="text-bold color-light-blue">{text_date_under_reserve_offer_process}</span></div>
            <div><span className='fa fa-calendar fa-fw' /> Post-Auction Sale End Date/Time: <span className="text-bold color-light-blue">{text_date_post_sale_end}</span></div>
          </div>
          <div className="tag-box tag-box-v3">
            <div className="section-summary" style={{ fontSize: '18px' }}>
              <span className='fa fa-history fa-fw' /> Auction {data.id} Action History
            </div>
            {data.timestamp_mode0 && <div><span className='fa fa-star-o fa-fw' /> Mode 0 activated: {data.timestamp_mode0}</div>}
            {data.timestamp_auction_item_generate && <div><span className='fa fa-database fa-fw' /> Auction Lots Generated: {data.timestamp_auction_item_generate}</div>}
            {data.timestamp_mode1 && <div><span className='fa fa-star-o fa-fw' /> Mode 1 activated: {data.timestamp_mode1}</div>}
            {data.timestamp_mode2 && <div><span className='fa fa-star-o fa-fw' /> Mode 2 activated: {data.timestamp_mode2}</div>}
            {data.timestamp_mode3 && <div><span className='fa fa-star-o fa-fw' /> Mode 3 activated: {data.timestamp_mode3}</div>}
            {data.timestamp_eoa_email && <div><span className='fa fa-envelope-o fa-fw' /> End of auction email sent: {data.timestamp_eoa_email}</div>}
            {data.timestamp_invoice_generate && <div><span className='fa fa-file-text-o fa-fw' /> Initial Invoices Generated: {data.timestamp_invoice_generate}</div>}
            {data.timestamp_mode4 && <div><span className='fa fa-star-o fa-fw' /> Mode 4 activated: {data.timestamp_mode4}</div>}
            {data.timestamp_mode5 && <div><span className='fa fa-star-o fa-fw' /> Mode 5 activated: {data.timestamp_mode5}</div>}
            {data.timestamp_statement_generate && <div><span className='fa fa-file-text-o fa-fw' /> Statements Generated: {data.timestamp_statement_generate}</div>}
          </div>
        </div>
      </div>

      <div className="flex-container-base" style={{ flexWrap: 'wrap', justifyContent: 'flex-end', columnGap: '10px' }}>
        <div style={{ flex: '0 0 290px', minWidth: 0 }}>
          <SimpleContainer style={{ height: '100%', display: 'flex', flexDirection: 'column', flexGrow: '1', alignItems: 'end', justifyContent: 'space-between', paddingTop: '10px' }}>
            <div>
              <button className="btn btn-u btn-u-sm rounded btn-u-primary" onClick={() => updateAuction(data.id)} style={{ lineHeight: '24px', marginLeft: '5px' }}><span style={{ fontWeight: 'bold' }}><span className="fa fa-fw fa-check-square" /> Update</span></button>
            </div>
          </SimpleContainer>
        </div>
      </div>
      <StatusBox data={auction.actions.update} className="margin-top-5" />
    </div>
  </FormRow>

  return <div>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <ContentPanel
      icon="fa fa-file-text-o fa-fw"
      title={title}
      beanText={data.archived ? 'Archived' : null}
      beanColor='#e67e22'
      showBackToList={true}
      backToListAction={_backToList}
      prevAction={auction.content.prev ? _prev : null}
      nextAction={auction.content.next ? _next : null}
      filteredText={formatQueryParams(location.query)}
      showPagingControls={true}
    >
      {content}
    </ContentPanel>
  </div>
}

function mapStateToProps(state) {
  return {
    auction: state.admin.auction,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getData, setFieldValue, updateAuction }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDetail)