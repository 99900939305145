import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Carousel } from 'react-responsive-carousel'
import objectFitImages from 'object-fit-images'

LotImageSlider.propTypes = {
  baseImagePath: PropTypes.string.isRequired,
  numImages: PropTypes.number.isRequired,
  zoomView: PropTypes.func.isRequired,
}

function LotImageSlider({ baseImagePath, numImages, zoomView }) {
  useEffect(() => {
    objectFitImages()
  },[])

  const _clickCarouselImage = (index) => {
    zoomView(index)
  }

  const _clickRawImage = () => {
    zoomView()
  }

  if (!numImages || numImages < 1) {
    return null
  } else if (numImages == 1) {
    return <div className="lot-image-container"><img src={baseImagePath + "A.jpg"} onClick={_clickRawImage} alt="" /></div>
  } else {
    const images = Array.apply(0, Array(numImages)).map((a, i) => {
      const suffix = String.fromCharCode(i+65)
      return <div key={i} className="lot-image-container">
        <img src={baseImagePath + suffix + ".jpg"} alt="" />
      </div>
    })
    return (
      <div className="lot-img-carousel-container">
        <Helmet>
          <link rel="stylesheet" href="/static/react-responsive-carousel/carousel.min.css" />
          <link rel="stylesheet" href="/static/css/react-responsive-carousel-custom.css" />
        </Helmet>
        <Carousel useKeyboardArrows showArrows={true} showStatus={true} dynamicHeight={false} onClickItem={_clickCarouselImage} thumbWidth={86}>
          {images}
        </Carousel>
      </div>
    )
  }
}

function propsAreEqual(prevProps, nextProps) {
  if (prevProps !== nextProps) {
    return prevProps.baseImagePath === nextProps.baseImagePath && prevProps.numImages === nextProps.numImages
  } else {
    return true
  }
}

export default React.memo(LotImageSlider, propsAreEqual)