import { isClient } from 'lib/exenv';
import React from 'react';
import { render, hydrate } from 'react-dom';
import { renderToString } from 'react-dom/server';
import { Router, applyRouterMiddleware, match, RouterContext, browserHistory } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux'
//import useragent from 'express-useragent';

import configureAppStore from './redux/store/configureAppStore';
import createRoutes from './Routes';
import Root from './app/base/Root';
import { status_defaults, validation_defaults } from 'lib/state_defaults';

if (isClient) {
  // require('es6-promise/auto') //polyfill browser environment with promise support - may not be needed anymore since fetch-ponyfill takes explicit Promise as argument
  // insert back to top replacement? maybe react-scroll-up?
  // if (__DEVELOPMENT__) {
  //   // disable for now because I think no code is using older jQuery syntax
  //   //require("jquery-migrate")
  // }
  //require("smoothscroll-for-websites")
  const store = configureAppStore(window.__INITIAL_STATE__);
  const history = syncHistoryWithStore(browserHistory, store)
  const renderMethod = process.env.NODE_ENV === 'production' ? hydrate : (module.hot ? render : hydrate)
  renderMethod(
    <Provider store={store}>
      <Router history={history} render={applyRouterMiddleware(useScroll())}>{createRoutes(store)}</Router>
    </Provider>,
    document.getElementById('root')
  );
}

function forceClientRender(res, store) {
  const helmet = Helmet.renderStatic();
  const initialState = store.getState();

  return res.send('<!doctype html>\n' + renderToString(
    <Root initialState={initialState} helmet={helmet} />
  ));
}

function renderComponentWithRoot(Component, componentProps, store) {
  const componentHtml = renderToString(
    <Provider store={store}>
      <Component {...componentProps} />
    </Provider>
  );

  const helmet = Helmet.renderStatic();
  const initialState = store.getState();

  return '<!doctype html>\n' + renderToString(
    <Root content={componentHtml} initialState={initialState} helmet={helmet} />
  );
}

function handleError(res, error) {
  res.status(500).send(error.message);
}

function handleRedirect(res, redirectLocation) {
  res.redirect(302, redirectLocation.pathname + redirectLocation.search);
}

function routeIsUnmatched(renderProps) {
  return renderProps.routes[renderProps.routes.length - 1].path === '*';
}

function getSessionData(req, res) {
  //console.log(req.headers.cookie); //raw header cookie data
  // console.log('userAgent:', req.headers['user-agent'])
  // const expressUserAgent = useragent.parse(req.headers['user-agent'])
  // console.log('expressUserAgent:', expressUserAgent)
  const user = req.user;
  if (user !== undefined)
  {
    try {
      console.log('session found: ', user);
      return {
        auth:
        {
          login:
          {
            data: {
              isAuthenticated: true,
              userInfo: {
                id: user.id,
                username: user.username,
                role: user.role,
                fullname: user.fullname,
                email: user.email,
                consignor: user.consignor,
              },
            },
            actions: {
              login: {
                meta: status_defaults,
                validation: validation_defaults,
              },
              logout: {
                meta: status_defaults,
              },
              forgotPassword: {
                meta: status_defaults,
                validation: validation_defaults,
                info: {
                  captchaFlag: false,
                }
              }
            },
            fields: {
              user_email: '',
              user_password: '',
            },
          },
        },
        cookie:
        {
          value: req.headers.cookie,
        }
      };
    } catch(err) {
      console.log('session error: ', user);
      return {};
    }
  } else {
    console.log('no session data present: ' + req.originalUrl);
    return {};
  }
}

function handleRoute(req, res, renderProps, store) {
  const status = routeIsUnmatched(renderProps) ? 404 : 200;
  const readyOnAllActions = renderProps.components
    .filter((component) => component.readyOnActions)
    .map((component) => component.readyOnActions(store.dispatch, renderProps.params, renderProps.location));

  Promise
    .all(readyOnAllActions)
    .then(() => store.dispatch({type: 'owa/cookie/CLEAR_LOCAL_COOKIE_DATA'}))
    .then(() => res.status(status).send(renderComponentWithRoot(RouterContext, renderProps, store)));
}

function serverMiddleware(req, res) {
  const session_state = getSessionData(req, res)
  const store = configureAppStore(session_state)

  if (__GENERAL_SETTINGS__.disableSSR) {
    console.log('Force Client-Side React Render')
    store.dispatch({type: 'owa/cookie/CLEAR_LOCAL_COOKIE_DATA'})
    return forceClientRender(res, store);
  }

  const routes = createRoutes(store)

  match({ routes, location: req.url }, (error, redirectLocation, renderProps) => {
    if (error) {
      handleError(error);
    } else if (redirectLocation) {
      handleRedirect(res, redirectLocation);
    } else if (renderProps) {
      handleRoute(req, res, renderProps, store);
    } else {
      // This should actually never happen, as Routes.js has a catch-all '*' path.
      res.sendStatus(404);
    }
  });
}

export default serverMiddleware;
