import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import AuctionTimer from './AuctionTimer'
import { formatDate } from 'lib/utility'

AuctionStatus.propTypes = {
  siteinfo: PropTypes.object.isRequired,
  siteinfo_meta: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  getSiteInfo: PropTypes.func.isRequired,
}

function AuctionStatus({ siteinfo, siteinfo_meta, credentials, getSiteInfo }) {
  let content = ""
  let show_notification = true

  if (siteinfo.auction_mode === 1) {
    content = <AuctionTimer siteinfo={siteinfo} siteinfo_meta={siteinfo_meta} getSiteInfo={getSiteInfo} />
  } else if (siteinfo.auction_mode === 2) {
    content = <div>
      Auction is in the <Link to="/catalog/current?open=true">10 minute rule - bid now!</Link>
    </div>
  } else if (siteinfo.auction_mode === 3 || siteinfo.auction_mode === 4) {
    content = <div>
      Auction is closed. Buy <Link to="/catalog/current?unsold=true">unsold lots</Link> now!
    </div>
  } else {
    const next_auction_start_date = formatDate(siteinfo.auction_mode === 0 ? siteinfo.auction_start_date : siteinfo.next_auction_start_date)
    show_notification = next_auction_start_date !== null
    content = <div>Next auction catalog online on {next_auction_start_date}</div>
  }

  return show_notification ? <div className="auction-status-container" style={credentials.userInfo.role > 1 ? {transform: 'none', left: '175px'} : null}>
    {content}
  </div> : null
}

export default AuctionStatus